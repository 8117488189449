// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container {
  text-align: center;
}

.header {
  color: white;
  font-size: 48px;
  width: 100%;
  margin-top: 45px;
  margin-bottom: 0;
  font-weight: 100;
}

.subheader {
  color: #e0ca00;
  width: 100%;
  text-align: center;
  font-size: 24px;
  margin-top: -15px;
  margin-bottom: 5px;
  font-weight: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.construction-monkey-1 {
  width: 45px;
}

.construction-monkey-2 {
  width: 50px;
}

.border-gradient {
  border: 10px solid;
  border-image-slice: 1;
  border-width: 2px;
  border-image-source: linear-gradient(to bottom, #bf00b7, #0c318f);
}

.main-content-container {
  width: 760px;
  height: 690px;
  background-color: black;
  margin: 20px auto 0 auto;
  position: relative;
}

.upper-section {
  width: 100%;
  height: 215px;
  display: flex;
  justify-content: space-evenly;
  align-items: start;
  margin-top: 30px;
}

.news-section {
  text-align: center;
}

.section-header {
  background-color: #e0ca00;
  color: black;
  padding: 0 15px;
  font-size: 34px;
  font-weight: 100;
  margin: 0 auto;
  display: inline-block;
  text-align: center;
}

.news-text {
  width: 290px;
  color: #959da5;
  font-size: 18px;
  font-weight: 100;
  text-align: center;
  padding-bottom: 0;
}

.now-text-container {
  width: 290px;
  padding-top: 8px;
}

.posts-section {
  margin-top: 40px;
  text-align: center;
}

.divider {
  width: 2px;
  height: 165px;
  background-color: #e0ca00;
  align-self: flex-end;
}

.badges-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.yellow {
  color: #e0ca00;
}`, "",{"version":3,"sources":["webpack://./src/components/home/home.scss","webpack://./src/styles/_colors.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;AADF;;AAIA;EACE,YAAA;EACA,eAAA;EACA,WAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA;AADF;;AAIA;EACE,cChBO;EDiBP,WAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AADF;;AAIA;EACE,WAAA;AADF;;AAIA;EACE,WAAA;AADF;;AAIA;EACE,kBAAA;EACA,qBAAA;EACA,iBAAA;EACA,iEAAA;AADF;;AAIA;EACE,YAAA;EACA,aAAA;EACA,uBAAA;EACA,wBAAA;EACA,kBAAA;AADF;;AAIA;EACE,WAAA;EACA,aAAA;EACA,aAAA;EACA,6BAAA;EACA,kBAAA;EACA,gBAAA;AADF;;AAIA;EACE,kBAAA;AADF;;AAIA;EACE,yBCjEO;EDkEP,YAAA;EACA,eAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;EACA,qBAAA;EACA,kBAAA;AADF;;AAIA;EACE,YAAA;EACA,cCzEK;ED0EL,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,iBAAA;AADF;;AAIA;EACE,YAAA;EACA,gBAAA;AADF;;AAIA;EACE,gBAAA;EACA,kBAAA;AADF;;AAIA;EACE,UAAA;EACA,aAAA;EACA,yBCjGO;EDkGP,oBAAA;AADF;;AAIA;EACE,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AADF;;AAIA;EACE,cC9GO;AD6GT","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
