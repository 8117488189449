// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.guestbook-graphics-container {
  height: 180px;
  width: 210px;
  background-color: black;
  border: 2px solid #bf00b7;
  position: absolute;
  top: -2px;
  right: -235px;
  box-sizing: border-box;
  padding-top: 11px;
}

.guestbook-desk {
  display: block;
  margin: 0 auto;
}

.guestbook-badge {
  display: block;
  margin: 10px auto;
}`, "",{"version":3,"sources":["webpack://./src/components/guestbook/guestbook-graphics/guestbook-graphics.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,YAAA;EACA,uBAAA;EACA,yBAAA;EACA,kBAAA;EACA,SAAA;EACA,aAAA;EACA,sBAAA;EACA,iBAAA;AADF;;AAIA;EACE,cAAA;EACA,cAAA;AADF;;AAIA;EACE,cAAA;EACA,iBAAA;AADF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
