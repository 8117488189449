import React, { useState } from 'react'
import Select from 'react-select'
import { eggImages, singleSelectStyles } from '../hatchery.logic'
import './egg-form.scss'

const EggForm = ({ selectedEgg, handleFormBack, submitForm }) => {
  const [name, setName] = useState('')
  const [sex, setSex] = useState('random')
  const [neighborhood, setNeighborhood] = useState('random')
  const [likes, setLikes] = useState('')
  const [ownerName, setOwnerName] = useState('')
  const [email, setEmail] = useState('')
  const [website, setWebsite] = useState('')
  const [shouldNotify, setShouldNotify] = useState(false)

  const handleNameChange = name => {
    if (name.length > 16) return
    setName(name)
  }

  const handleLikesChange = likes => {
    if (likes.length > 140) return
    setLikes(likes)
  }

  const handleOwerNameChange = ownerName => {
    if (ownerName.length > 16) return
    setOwnerName(ownerName)
  }

  const handleEmailChange = email => {
    if (email.length > 255) return
    setEmail(email)

    if (!email) setShouldNotify(false)
  }

  const handleWebsiteChange = website => {
    if (website.length > 1200) return
    setWebsite(website)
  }

  const handleSubmitForm = () => {
    if (!name) return
    const formData = {
      name,
      sex,
      neighborhood,
      likes,
      ownerName,
      email,
      website,
      shouldNotify,
      egg: selectedEgg.id,
    }
    submitForm(formData)
  }

  const sexOptions = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
    { value: 'random', label: 'Random' },
  ]

  const neighborhoodOptions = [
    { value: 'outer space', label: 'Outer Space 🪐' },
    { value: 'pallet town', label: 'Pallet Town 🏘️' },
    { value: 'main street', label: 'Main Street ☕️' },
    { value: 'pirate cove', label: 'Pirate Cove 🏴‍☠️' },
    { value: 'midgar', label: 'Midgar 🌆' },
    { value: 'destiny island', label: 'Destiny Island 🏝️' },
    { value: 'atlantis', label: 'Atlantis 🐟' },
    { value: 'random', label: 'Random 🎲' },
  ]

  return (
    <div className="egg-form-container">
      <div className="form-top-section">
        <img
          className="selected-egg-image"
          src={eggImages[selectedEgg.imageSlug]}
        />
        <div className="egg-name-container">
          <span className="egg-name-label">Pick a name for your egg.</span>
          <input
            className="egg-name-input"
            value={name}
            onChange={e => handleNameChange(e.target.value)}
            placeholder="Name..."
          />
        </div>
      </div>
      <span className="egg-section-header">
        And some more optional details...
      </span>
      <div className="form-section">
        <div className="form-labels-container">
          <span>Sex</span>
          <span>Neighborhood</span>
          <span>Likes</span>
        </div>
        <div className="form-inputs-container">
          <Select
            options={sexOptions}
            styles={singleSelectStyles}
            isSearchable={false}
            onChange={option => setSex(option?.value)}
            value={sexOptions.filter(x => sex === x.value)}
          />
          <Select
            options={neighborhoodOptions}
            styles={singleSelectStyles}
            isSearchable={false}
            onChange={option => setNeighborhood(option?.value)}
            value={neighborhoodOptions.filter(x => neighborhood === x.value)}
          />
          <textarea
            className="egg-likes-input"
            placeholder="Likes..."
            value={likes}
            onChange={e => handleLikesChange(e.target.value)}
          />
        </div>
      </div>
      <span className="egg-section-header">Optional info about you...</span>
      <div className="form-section">
        <div className="form-labels-container">
          <span>Name</span>
          <span>Email</span>
          <span>Website</span>
          <span className="sublabel">Visible to others</span>
        </div>
        <div className="form-inputs-container">
          <input
            className="egg-text-input"
            value={ownerName}
            onChange={e => handleOwerNameChange(e.target.value)}
            placeholder="Name..."
          />
          <input
            className="egg-text-input"
            value={email}
            onChange={e => handleEmailChange(e.target.value)}
            placeholder="Email..."
          />
          <input
            className="egg-text-input"
            value={website}
            onChange={e => handleWebsiteChange(e.target.value)}
            placeholder="Website..."
          />
          <div className="privacy-info">
            These fields are optional, but if you leave email blank, you won't
            be able to sponsor it after it hatches.
            <br />
            <strong>Name and email will be kept private.</strong>
          </div>
        </div>
      </div>
      <div className="form-bottom-section">
        <input
          type="checkbox"
          value="should-notify"
          id="should-notify"
          onChange={e => setShouldNotify(e.target.checked)}
          disabled={!email}
          checked={shouldNotify}
        />
        <span className="notify-text">
          Notify me via email when my egg hatches
        </span>
        <div className="form-buttons-container">
          <button onClick={handleFormBack}>Back</button>
          <button onClick={handleSubmitForm}>Submit</button>
        </div>
      </div>
    </div>
  )
}

export default EggForm
