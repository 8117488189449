// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.left-about-container {
  height: 355px;
  width: 210px;
  background-color: black;
  border: 2px solid #0c318f;
  position: absolute;
  top: 283px;
  left: -235px;
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 20px;
}

.feeling-header {
  display: block;
  width: 150px;
  text-align: center;
  font-size: 18px;
  margin: 10px auto 0 auto;
}

.imood {
  display: block;
  margin: 5px auto;
  min-height: 15px;
}

.photo-container {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mini-photo {
  width: 133px;
  height: 133px;
  object-fit: cover;
}

.msn-email {
  width: 100%;
  font-size: 14px;
  text-align: center;
  display: inline-block;
  margin: 23px auto 0 auto;
}

.msn-section {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 7px auto 0 auto;
}

.msn-callout {
  color: white;
  font-size: 16px;
  width: 114px;
  text-align: center;
}

.msn-icon {
  width: 64px;
  margin-right: -5px;
}

.msn-link {
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/home/left-about/left-about.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,YAAA;EACA,uBAAA;EACA,yBAAA;EACA,kBAAA;EACA,UAAA;EACA,YAAA;EACA,sBAAA;EACA,iBAAA;EACA,oBAAA;AADF;;AAIA;EACE,cAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,wBAAA;AADF;;AAIA;EACE,cAAA;EACA,gBAAA;EACA,gBAAA;AADF;;AAIA;EACE,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AADF;;AAIA;EACE,YAAA;EACA,aAAA;EACA,iBAAA;AADF;;AAIA;EACE,WAAA;EACA,eAAA;EACA,kBAAA;EACA,qBAAA;EACA,wBAAA;AADF;;AAIA;EACE,WAAA;EACA,aAAA;EACA,uBAAA;EACA,uBAAA;AADF;;AAIA;EACE,YAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;AADF;;AAIA;EACE,WAAA;EACA,kBAAA;AADF;;AAIA;EACE,YAAA;AADF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
