// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.email-subheader {
  font-size: 22px;
  margin-top: 17px;
  display: block;
}

.email-form {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 70%;
  margin: 15px auto 0 auto;
  text-align: left;
}

.email-form span {
  font-size: 22px;
}

.email-form input {
  margin-bottom: 18px;
  border: 2px solid #0075ff;
  height: 20px;
}

.email-form textarea {
  height: 150px;
  resize: none;
  outline: none;
  border: 2px solid #0075ff;
}

.email-form button {
  border: none;
  cursor: pointer;
  align-self: flex-end;
  background-color: #0075ff;
  color: black;
  padding: 0 25px;
  font-size: 34px;
}

.email-form button:hover {
  color: white;
}

.privacy-note {
  font-size: 16px !important;
  position: relative;
}

.contact-error-message {
  color: #e0ca00;
  font-size: 12px !important;
  position: absolute;
  left: 0;
  bottom: -20px;
}

.contact-success-message {
  color: limegreen;
  font-size: 14px !important;
  position: absolute;
  left: 0;
  bottom: -20px;
}

.contact-me-text {
  width: 70%;
  margin: 0 auto;
  text-align: center;
  font-size: 18px;
  margin-top: 10px;
}

.contact-me-text strong {
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/contact/contact.scss","webpack://./src/styles/_colors.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AADF;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,UAAA;EACA,wBAAA;EACA,gBAAA;AADF;;AAIA;EACE,eAAA;AADF;;AAIA;EACE,mBAAA;EACA,yBAAA;EACA,YAAA;AADF;;AAIA;EACE,aAAA;EACA,YAAA;EACA,aAAA;EACA,yBAAA;AADF;;AAIA;EACE,YAAA;EACA,eAAA;EACA,oBAAA;EACA,yBCrCK;EDsCL,YAAA;EACA,eAAA;EACA,eAAA;AADF;;AAIA;EACE,YAAA;AADF;;AAIA;EACE,0BAAA;EACA,kBAAA;AADF;;AAIA;EACE,cCtDO;EDuDP,0BAAA;EACA,kBAAA;EACA,OAAA;EACA,aAAA;AADF;;AAIA;EACE,gBAAA;EACA,0BAAA;EACA,kBAAA;EACA,OAAA;EACA,aAAA;AADF;;AAIA;EACE,UAAA;EACA,cAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;AADF;;AAIA;EACE,YAAA;AADF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
