import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import './poll-box.scss'

import moogle1 from '../../../media/guestbook/moogle1.gif'
import moogle2 from '../../../media/guestbook/moogle2.gif'
import moogle3 from '../../../media/guestbook/moogle3.gif'
import sadSmash from '../../../media/guestbook/sad-smash.gif'
import torticon from '../../../media/guestbook/torticon.gif'
import torticonBack from '../../../media/guestbook/torticon-back.gif'
import neopets from '../../../media/guestbook/neopets-button.gif'
import tamagotchi from '../../../media/guestbook/tamagotchi-orange.gif'
import webkinz from '../../../media/guestbook/webkinz-logo.png'
import pikachu from '../../../media/guestbook/pikasleep.gif'

const PollBox = () => {
  const [userVote, setUserVote] = useState(null)
  const [userDailyVote, setUserDailyVote] = useState(
    Cookies.get('userDailyVote')
  )
  const [poll, setPoll] = useState(null)
  const [selectedOption, setSelectedOption] = useState(null)
  const [totalVotes, setTotalVotes] = useState(0)

  useEffect(() => {
    const getActivePoll = async () => {
      const activePoll = await axios.get('/api/polls')
      if (!activePoll.data) return

      setPoll(activePoll.data)
      const userVoteFromCookie = Cookies.get(`userVote-${activePoll.data.id}`)
      setUserVote(userVoteFromCookie ? parseInt(userVoteFromCookie) : null)
      handleVoteTotals(activePoll.data.options)
    }
    getActivePoll()
  }, [])

  const handleVoteTotals = options => {
    setTotalVotes(
      options.reduce((acc, option) => {
        return acc + option.votes
      }, 0)
    )
  }

  const handleVote = async () => {
    const response = await axios.put(`/api/polls/${selectedOption.id}`)
    if (response.data) {
      const updatedPoll = {
        ...poll,
        options: poll.options.map(option => {
          if (option.id === selectedOption.id) {
            return {
              ...option,
              votes: option.votes + 1,
            }
          }
          return option
        }),
      }
      setPoll(updatedPoll)
      setUserVote(selectedOption.id)
      handleVoteTotals(updatedPoll.options)
      Cookies.set(`userVote-${poll.id}`, selectedOption.id, { expires: 30 })
    }
  }

  const handleDailyVote = vote => {
    const voteValue = vote ? 'yes' : 'no'
    axios.post(`/api/polls/daily-vote?enjoyed=${vote}`)
    setUserDailyVote(voteValue)
    Cookies.set('userDailyVote', voteValue, { expires: 1 })
  }

  const renderPetPollImage = userVote => {
    console.log('got here', userVote)
    switch (userVote) {
      case 15:
        return <img alt="Neopets" src={neopets} />
      case 16:
        return <img alt="Tamagotchi" src={tamagotchi} />
      case 17:
        return <img alt="Webkinz" src={webkinz} />
      case 18:
        return <img alt="Pikachu" src={pikachu} />
      default:
        return null
    }
  }

  const ActivePoll = () => {
    return (
      <div className="poll">
        <span className="poll-box-text">{poll.name}</span>
        <div className="poll-options">
          {userVote
            ? poll.options.map((option, i) => {
                const userVoteClass = `poll-option ${
                  userVote === option.id ? 'user-vote' : ''
                }`
                return (
                  <div className={userVoteClass} key={i}>
                    <span className="poll-option-name">{option.name}</span>
                    <span className="poll-option-votes">{`${
                      option.votes
                    } (${Math.floor(
                      (option.votes / totalVotes) * 100
                    )}%)`}</span>
                  </div>
                )
              })
            : poll.options.map((option, i) => {
                return (
                  <div className="poll-option-unvoted" key={i}>
                    <input
                      type="radio"
                      id={option.id}
                      name="poll-option"
                      value={option.id}
                      checked={selectedOption === option}
                      onChange={() => setSelectedOption(option)}
                    />
                    <label className="option-label" htmlFor={option.id}>
                      {option.name}
                    </label>
                  </div>
                )
              })}
          {!userVote && (
            <button
              className="vote-button"
              disabled={!selectedOption}
              onClick={handleVote}
            >
              vote
            </button>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="poll-box-container">
      <h3 className="poll-box-header">polls</h3>
      <div className="moogle-row">
        <img src={moogle1} alt="moogle" />
        <img src={moogle2} alt="moogle" />
        <img src={moogle3} alt="moogle" />
      </div>
      {poll ? (
        <ActivePoll />
      ) : (
        <span className="poll-box-text">
          there are no active polls right now
        </span>
      )}
      {poll && poll.id === 4 && userVote && (
        <div className="pet-images">{renderPetPollImage(userVote)}</div>
      )}
      {!userDailyVote ? (
        <div className="daily-question">
          <span className="poll-box-text">did you enjoy your visit today?</span>
          <div className="daily-question-buttons">
            <button
              className="vote-button"
              onClick={() => handleDailyVote(true)}
            >
              yes
            </button>
            <button
              className="vote-button"
              onClick={() => handleDailyVote(false)}
            >
              no
            </button>
          </div>
        </div>
      ) : userDailyVote === 'no' ? (
        <div className="daily-question">
          <span className="a-splode-text">YOUR HEAD A SPLODE</span>
          <img className="sad-smash" src={sadSmash} />
        </div>
      ) : (
        <div className="daily-question">
          {/* <span className="poll-box-text">thanks for the feedback!</span> */}
          <div className="torticon-container">
            <img className="torticon-static" src={torticon} />
            <img className="torticon-active" src={torticonBack} />
          </div>
        </div>
      )}
    </div>
  )
}

export default PollBox
