import cursor1 from '../../../../media/cursors/rainbow-flash.gif'
import cursor2 from '../../../../media/cursors/fire.gif'
import cursor3 from '../../../../media/cursors/paper-airplane.gif'
import cursor4 from '../../../../media/cursors/sword.gif'
import cursor5 from '../../../../media/cursors/rainbow.gif'
import cursor6 from '../../../../media/cursors/magic.gif'
import cursor7 from '../../../../media/cursors/bounce.gif'
import cursor8 from '../../../../media/cursors/monkey.gif'
import cursor9 from '../../../../media/cursors/wii.png'

export const cursors = [
  { name: 'flash', src: cursor1 },
  { name: 'fire', src: cursor2 },
  { name: 'airplane', src: cursor3 },
  { name: 'sword', src: cursor4 },
  { name: 'rainbow', src: cursor5 },
  { name: 'magic', src: cursor6 },
  { name: 'bounce', src: cursor7 },
  { name: 'monkey', src: cursor8 },
  { name: 'wii', src: cursor9 },
]
