import React from 'react'
import './blinky-box.scss'

import aquariumJunkie from '../../../media/blinkies/aquarium-junkie.gif'
import badWebDesign from '../../../media/blinkies/bad-web-design.gif'
import broMyNeck from '../../../media/blinkies/bro-my-neck.gif'
import centipede from '../../../media/blinkies/centipede.gif'
import clumpPenguin from '../../../media/blinkies/clump-penguin.gif'
import despiteEverything from '../../../media/blinkies/despite-everything.gif'
import fishDoTheirJob from '../../../media/blinkies/fish-do-their-job.gif'
import herobrineIsReal from '../../../media/blinkies/herobrine-is-real.gif'
import homeAddress from '../../../media/blinkies/home-address.gif'
import letsDdr from '../../../media/blinkies/lets-ddr.gif'
import milk from '../../../media/blinkies/milk.gif'
import mommyLizard from '../../../media/blinkies/mommy-lizard.gif'
import nyquil from '../../../media/blinkies/nyquil.gif'
import time2Crouch from '../../../media/blinkies/time-2-crouch.gif'
import webkinz from '../../../media/blinkies/webkinz.gif'
import wishItWas2001 from '../../../media/blinkies/wish-it-was-2001.gif'

const blinkyImages = [
  aquariumJunkie,
  badWebDesign,
  broMyNeck,
  centipede,
  clumpPenguin,
  despiteEverything,
  fishDoTheirJob,
  herobrineIsReal,
  homeAddress,
  letsDdr,
  milk,
  mommyLizard,
  nyquil,
  time2Crouch,
  webkinz,
  wishItWas2001,
]

const renderBlinkies = () => {
  return blinkyImages.map((image, i) => {
    return <img className="blinky" src={image} key={i} />
  })
}

const BlinkyBox = () => {
  return <div className="blinky-box-container">{renderBlinkies()}</div>
}

export default BlinkyBox
