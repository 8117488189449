import React from 'react'
import { Link } from 'react-router-dom'
import './hatchery-box.scss'

import redEgg from '../../../media/eggs/red-stripe.gif'
import purpleEgg from '../../../media/eggs/purple-spots.gif'
import blueEgg from '../../../media/eggs/blue-flecked.gif'
import orangeEgg from '../../../media/eggs/orange-ripple.gif'

const HatcheryBox = () => {
  return (
    <div className="hatchery-box-container">
      <span className="hatchery-box-header">hatchery</span>
      <span className="hatchery-box-text">
        have a code from visiting early? <br />
        <br />
        <Link to="/hatchery">click here</Link> to enter your code!
      </span>
      <div className="home-eggs">
        <img className="home-egg" src={redEgg} />
        <img className="home-egg" src={purpleEgg} />
        <img className="home-egg" src={blueEgg} />
        <img className="home-egg" src={orangeEgg} />
      </div>
    </div>
  )
}

export default HatcheryBox
