import React from 'react'
import { eggImages } from '../hatchery.logic'
import './egg-success.scss'

const EggSuccess = ({ egg, handleFormReset }) => {
  // TODO - Make this image available at this url
  const html = `<a href="https://gloogo.io/hatchery><img src="https://gloogo.io/images/eggs/${egg.imageSlug}.gif"/></a>`

  return (
    <div className="egg-success-container">
      <h2 className="egg-success-header">Congratulations!</h2>
      <img className="egg-success-image" src={eggImages[egg.imageSlug]} />
      <span className="egg-name">{egg.name}</span>
      <span className="egg-success-message">
        You have claimed an egg! Below you'll find some HTML to add it to your
        site, if you so choose.
      </span>
      {egg.shouldNotify && (
        <span className="notify-message">
          I'll notify you know when it hatches!
        </span>
      )}
      <textarea className="egg-html" value={html} />
      <button className="form-reset-button" onClick={handleFormReset}>
        Back to Hatchery
      </button>
    </div>
  )
}

export default EggSuccess
