import React, { useState, useEffect } from 'react'
import axios from 'axios'
import './left-about.scss'

import msn from '../../../media/msn-messenger-small.png'

const LeftAbout = () => {
  const [photo, setPhoto] = useState(null)

  useEffect(() => {
    const getRecentPhoto = async () => {
      try {
        const response = await axios.get('/api/blog/recent-photos?limit=1')
        setPhoto(response.data[0].url)
      } catch (error) {}
    }
    getRecentPhoto()
  }, [])

  return (
    <div className="left-about-container">
      <span className="feeling-header">gloogo is feeling</span>
      <a href="https://www.imood.com/users/gloogo">
        <img
          className="imood"
          src="https://moods.imood.com/display/uname-gloogo/fg-000000/bg-ffffff/imood.gif"
          alt="The current mood of gloogo at www.imood.com"
          border="0"
        />
      </a>
      <div className="photo-container">
        <img src={photo} className="mini-photo" />
      </div>
      <span className="msn-email">jordan@escargot.chat</span>
      <div className="msn-section">
        <img className="msn-icon" src={msn} />
        <span className="msn-callout">
          <a className="msn-link" href="https://escargot.chat/">
            chat with me on msn messenger
          </a>
        </span>
      </div>
    </div>
  )
}

export default LeftAbout
