// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.egg-form-container {
  margin-top: 20px;
}

.form-top-section {
  display: flex;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
}

.selected-egg-image {
  width: 110px;
  vertical-align: bottom;
  display: inline-block;
}

.egg-name-container {
  margin-left: 30px;
}

.egg-name-label {
  display: block;
  font-size: 18px;
  color: white;
  margin-top: 20px;
}

.egg-section-header {
  color: white;
  font-size: 18px;
  width: 100%;
  margin-top: 25px;
  text-align: center;
  display: inline-block;
  margin-bottom: 10px;
}

.egg-name-input {
  display: inline-block;
  border: 2px solid #e0ca00;
  font-size: 24px;
  width: 230px;
  height: 36px;
  background-color: black;
  color: white;
  padding-left: 8px;
  margin-top: 20px;
}

.egg-likes-input {
  display: inline-block;
  border: 2px solid #e0ca00;
  font-size: 18px;
  width: 200px;
  min-height: 64px;
  background-color: black;
  color: white;
  padding-left: 8px;
  margin-top: 13px;
  outline: none;
  padding-top: 8px;
  margin-left: 20px;
  box-sizing: border-box;
}

.egg-text-input {
  display: block;
  border: 2px solid #e0ca00;
  font-size: 18px;
  width: 200px;
  height: 34px;
  background-color: black;
  color: white;
  padding-left: 8px;
  margin-top: 13px;
  margin-left: 82px;
  box-sizing: border-box;
}

.form-section {
  display: inline-flex;
  margin: 0 auto;
}

.form-labels-container {
  display: inline-flex;
  text-align: left;
  flex-direction: column;
  margin-top: 3px;
  position: relative;
}

.form-labels-container span {
  font-size: 24px;
  color: white;
  margin-top: 19px;
}

.sublabel {
  font-size: 10px !important;
  color: #959da5 !important;
  position: absolute;
  left: 0;
  bottom: -12px;
  width: 100px;
}

.form-inputs-container {
  position: relative;
}

.privacy-info {
  position: absolute;
  border: 2px solid #e0ca00;
  bottom: -10px;
  right: -230px;
  width: 190px;
  height: 170px;
  text-align: left;
  font-size: 18px;
  color: #959da5;
  margin-top: 10px;
  padding: 5px 5px 0px 5px;
}

.privacy-info strong {
  color: white;
}

.notify-text {
  font-size: 12px;
}

.form-bottom-section {
  margin-top: 25px;
}

.form-buttons-container button {
  background-color: #131418;
  color: #959da5;
  vertical-align: top;
  margin-top: 10px;
  margin-left: 15px;
  font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/components/hatchery/egg-form/egg-form.scss","webpack://./src/styles/_colors.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;AADF;;AAIA;EACE,aAAA;EACA,cAAA;EACA,WAAA;EACA,uBAAA;AADF;;AAIA;EACE,YAAA;EACA,sBAAA;EACA,qBAAA;AADF;;AAIA;EACE,iBAAA;AADF;;AAIA;EACE,cAAA;EACA,eAAA;EACA,YAAA;EACA,gBAAA;AADF;;AAIA;EACE,YAAA;EACA,eAAA;EACA,WAAA;EACA,gBAAA;EACA,kBAAA;EACA,qBAAA;EACA,mBAAA;AADF;;AAIA;EACE,qBAAA;EACA,yBAAA;EACA,eAAA;EACA,YAAA;EACA,YAAA;EACA,uBAAA;EACA,YAAA;EACA,iBAAA;EACA,gBAAA;AADF;;AAIA;EACE,qBAAA;EACA,yBAAA;EACA,eAAA;EACA,YAAA;EACA,gBAAA;EACA,uBAAA;EACA,YAAA;EACA,iBAAA;EACA,gBAAA;EACA,aAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;AADF;;AAIA;EACE,cAAA;EACA,yBAAA;EACA,eAAA;EACA,YAAA;EACA,YAAA;EACA,uBAAA;EACA,YAAA;EACA,iBAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;AADF;;AAIA;EACE,oBAAA;EACA,cAAA;AADF;;AAIA;EACE,oBAAA;EACA,gBAAA;EACA,sBAAA;EACA,eAAA;EACA,kBAAA;AADF;;AAIA;EACE,eAAA;EACA,YAAA;EACA,gBAAA;AADF;;AAIA;EACE,0BAAA;EACA,yBAAA;EACA,kBAAA;EACA,OAAA;EACA,aAAA;EACA,YAAA;AADF;;AAIA;EACE,kBAAA;AADF;;AAIA;EACE,kBAAA;EACA,yBAAA;EACA,aAAA;EACA,aAAA;EACA,YAAA;EACA,aAAA;EACA,gBAAA;EACA,eAAA;EACA,cCvHK;EDwHL,gBAAA;EACA,wBAAA;AADF;;AAIA;EACE,YAAA;AADF;;AAIA;EACE,eAAA;AADF;;AAIA;EACE,gBAAA;AADF;;AAIA;EACE,yBAAA;EACA,cC1IK;ED2IL,mBAAA;EACA,gBAAA;EACA,iBAAA;EACA,eAAA;AADF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
