import React from 'react'
import './now-playing.scss'

const PLAYING = 'FFVII Rebirth'
const LEARNING = 'Python'
const BUILDING = 'Reader View'
const WATCHING = 'The Chosen'

const NowPlaying = () => {
  return (
    <div className="now-text-container">
      <p className="now-text">
        <strong>Playing:</strong> {PLAYING}
      </p>
      <p className="now-text">
        <strong>Learning:</strong> {LEARNING}
      </p>
      <p className="now-text">
        <strong>Building:</strong> {BUILDING}
      </p>
      <p className="now-text">
        <strong>Watching: </strong>
        <a href="https://www.youtube.com/watch?v=K1-FoFj8Jbo" target="_blank">
          {WATCHING}
        </a>
      </p>
    </div>
  )
}

export default NowPlaying
