// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.friend-box-container {
  height: 458px;
  width: 210px;
  background-color: black;
  border: 2px solid #bf00b7;
  position: absolute;
  bottom: -2px;
  left: -235px;
}

.friends-container {
  margin: 20px auto 140px auto;
}

.badge-html {
  margin-top: 10px;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif !important;
  width: 70%;
  height: 75px;
  background-color: black;
  color: white;
  scrollbar-width: none;
}

.friends-header {
  color: white;
  display: block;
  width: 100%;
  text-align: center;
  font-size: 24px;
  margin: 10px auto;
  font-weight: 100;
}

.sad-gifs {
  position: absolute;
  bottom: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.bottom-gif {
  margin-bottom: -7px;
}`, "",{"version":3,"sources":["webpack://./src/components/links/friend-box/friend-box.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,YAAA;EACA,uBAAA;EACA,yBAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;AADF;;AAIA;EACE,4BAAA;AADF;;AAIA;EACE,gBAAA;EACA,eAAA;EACA,oDAAA;EACA,UAAA;EACA,YAAA;EACA,uBAAA;EACA,YAAA;EACA,qBAAA;AADF;;AAIA;EACE,YAAA;EACA,cAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;AADF;;AAIA;EACE,kBAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,qBAAA;AADF;;AAIA;EACE,mBAAA;AADF","sourcesContent":["@import '../../../styles/colors';\n\n.friend-box-container {\n  height: 458px;\n  width: 210px;\n  background-color: black;\n  border: 2px solid $pink;\n  position: absolute;\n  bottom: -2px;\n  left: -235px;\n}\n\n.friends-container {\n  margin: 20px auto 140px auto;\n}\n\n.badge-html {\n  margin-top: 10px;\n  font-size: 12px;\n  font-family: Arial, Helvetica, sans-serif !important;\n  width: 70%;\n  height: 75px;\n  background-color: black;\n  color: white;\n  scrollbar-width: none;\n}\n\n.friends-header {\n  color: white;\n  display: block;\n  width: 100%;\n  text-align: center;\n  font-size: 24px;\n  margin: 10px auto;\n  font-weight: 100;\n}\n\n.sad-gifs {\n  position: absolute;\n  bottom: 15px;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: flex-end;\n}\n\n.bottom-gif {\n  margin-bottom: -7px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
