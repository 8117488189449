// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-container {
  height: 262px;
  width: 210px;
  background-color: black;
  border: 2px solid #bf00b7;
  position: absolute;
  top: -2px;
  left: -235px;
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 20px;
}

.nav-item {
  display: block;
  width: 100%;
  margin-top: 10px;
  font-size: 24px;
  color: #0075ff;
  font-weight: 100;
  text-align: center;
  cursor: pointer;
}

.nav-item:hover {
  color: #bf00b7;
}

.unfinished {
  text-decoration: line-through;
}

.unfinished:hover {
  color: #959da5 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/common/nav-box/nav-box.scss","webpack://./src/styles/_colors.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,YAAA;EACA,uBAAA;EACA,yBAAA;EACA,kBAAA;EACA,SAAA;EACA,YAAA;EACA,sBAAA;EACA,iBAAA;EACA,oBAAA;AADF;;AAIA;EACE,cAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;EACA,cCnBK;EDoBL,gBAAA;EACA,kBAAA;EACA,eAAA;AADF;;AAIA;EACE,cCxBK;ADuBP;;AAIA;EACE,6BAAA;AADF;;AAIA;EACE,yBAAA;AADF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
