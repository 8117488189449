import React, { useState, useEffect } from 'react'
import NavBox from '../common/nav-box/nav-box'
import Comments from './comments/comments'
import PollBox from './poll-box/poll-box'
import GuestbookGraphics from './guestbook-graphics/guestbook-graphics'
import axios from 'axios'
import './guestbook.scss'

const Guestbook = () => {
  const [name, setName] = useState('')
  const [website, setWebsite] = useState('')
  const [message, setMessage] = useState('')
  const [statusMessage, setStatusMessage] = useState('')
  const [status, setStatus] = useState('')
  const [comments, setComments] = useState([])

  useEffect(() => {
    getGuesbookComments()
  }, [])

  const getGuesbookComments = async () => {
    const messages = await axios.get('/api/guestbook')
    if (!messages.data) return

    setComments(messages.data)
  }

  const handleSubmit = async () => {
    if (!name || !message) {
      setStatus('error')
      setStatusMessage('Please include your name and a message.')
      return
    }
    const postData = {
      name,
      website,
      message,
    }
    try {
      const response = await axios.post('/api/guestbook', {
        postData,
      })

      if (!response.data.id) {
        setStatus('error')
        setStatusMessage('There was a problem sending the message.')
        return
      }

      clearForm()
      setStatus('success')
      setStatusMessage('Message sent!')
    } catch (error) {
      setStatus('error')
      setStatusMessage('There was a problem sending the message.')
      return
    }
    getGuesbookComments()
  }

  const clearForm = () => {
    setName('')
    setWebsite('')
    setMessage('')
  }

  const handleNameChange = value => {
    if (value.length > 26) return
    setName(value)
  }

  const handleWebsiteChange = value => {
    if (value.length > 360) return
    setWebsite(value)
  }

  const handleMessageChange = value => {
    if (value.length > 1000) return
    setMessage(value)
  }

  return (
    <div className="secondary-page">
      <h1 className="page-header">guestbook</h1>
      <div className="page-content-container">
        <NavBox />
        <PollBox />
        <GuestbookGraphics />
        <Comments comments={comments} />
        <h3 className="page-section-header">leave a message</h3>
        <div className="guestbook-form">
          <div className="guestbook-form-upper-fields">
            <div className="guestbook-form-field">
              <span>name</span>
              <input
                className="guestbook-name-input"
                value={name}
                onChange={e => handleNameChange(e.target.value)}
              />
            </div>
            <div className="guestbook-form-field">
              <span>website</span>
              <input
                value={website}
                onChange={e => handleWebsiteChange(e.target.value)}
              />
            </div>
          </div>
          <span>message</span>
          <textarea
            value={message}
            onChange={e => handleMessageChange(e.target.value)}
          />
          <span className="privacy-note">
            all info entered here will be visible to others
            {statusMessage && (
              <span className={`guestbook-${status}-message`}>
                {statusMessage}
              </span>
            )}
          </span>
          <button className="guestbook-submit-button" onClick={handleSubmit}>
            post
          </button>
        </div>
      </div>
    </div>
  )
}

export default Guestbook
