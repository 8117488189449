import React from 'react'
import './nature.scss'

const tree = `
     ccee88oo
  C8O8O8Q8PoOb o8oo
 dOB69QO8PdUOpugoX9bD
CgggXU8OU qOp qOdoUOdcb
    6OuU  /p u gcoUodpP
      \\\\\\//  /doXUP
        \\\\\\////
         |||/\\
         |||\\/
         |||||
   .....//||||\\....
`

const fruit = [
  [
    '🍒',
    'https://web.archive.org/web/20091027000641/http://geocities.com/oynotgoats/',
  ],
  [
    '🍎',
    'https://web.archive.org/web/20090902223750/http://geocities.com/longlivetheevilqueen/queenindex.html',
  ],
  [
    '🍐',
    'https://web.archive.org/web/20090831072158/http://geocities.com/catalunatic2001/desserts.htm',
  ],
  [
    '🍊',
    'https://web.archive.org/web/20091020054516/http://hk.geocities.com/wanfamilyhk/download/download.htm',
  ],
  [
    '🍑',
    'https://web.archive.org/web/20091027143316/http://geocities.com/compasionateladies/ppquilt/prayerquiltpg2.html',
  ],
]

const Nature = () => {
  const treeArray = tree.split('')
  const randomFruit = fruit[Math.floor(Math.random() * fruit.length)]

  return (
    <div className="wwoctober-page">
      <div className="ascii-container">
        <pre>
          <code className="ascii-tree">
            {treeArray.map(char => {
              return char === 'X'
                ? // <a key={index} href={randomFruit[1]}>
                  randomFruit[0]
                : // </a>
                  char
            })}
          </code>
        </pre>
        <div className="darktext">Want to headbutt it?</div>
      </div>
    </div>
  )
}

export default Nature
