import React, { useState, useEffect } from 'react'
import axios from 'axios'
import UpdatesList from './updates-list/updates-list'
import './updates.scss'

import logOff from '../../../media/blinkies/log-off.gif'
import tummyHurty from '../../../media/blinkies/tummy-hurty.gif'
import catDead from '../../../media/blinkies/cat-dead.gif'
import sadFlying from '../../../media/blinkies/sad-flying.gif'

const Updates = () => {
  const [updates, setUpdates] = useState([])

  useEffect(() => {
    const getUpdates = async () => {
      try {
        const response = await axios.get('/api/blog/updates')
        if (response.data) setUpdates(response.data)
      } catch (error) {}
    }
    getUpdates()
  }, [])

  return (
    <div className="updates-container">
      <span className="updates-header">updates</span>
      {updates.length > 0 && <UpdatesList updates={updates} />}
      <div className="footer-blinkies">
        <img src={logOff} />
        <img src={tummyHurty} />
        <img src={catDead} />
        <img src={sadFlying} />
      </div>
    </div>
  )
}

export default Updates
