import React, { useState, useEffect } from 'react'
import './slurp-town.scss'

// All this work to avoid using a stupid date library
// Was it worth it, kids?
const isDaylightSavingTime = currentDate => {
  const january = new Date(currentDate.getFullYear(), 0, 1)
  const july = new Date(currentDate.getFullYear(), 6, 1)
  const standardTimezoneOffset = Math.max(
    january.getTimezoneOffset(),
    july.getTimezoneOffset()
  )
  return currentDate.getTimezoneOffset() < standardTimezoneOffset
}

const SlurpTown = () => {
  const [slurpTime, setSlurpTime] = useState(new Date())
  const [isSlurpTownOpen, setIsSlurpTownOpen] = useState(false)

  useEffect(() => {
    const updateSlurpTownStatus = () => {
      const currentTime = new Date()
      const offsetInHours = currentTime.getTimezoneOffset() / 60

      const mtOffset = isDaylightSavingTime(currentTime) ? 6 : 7

      const mtTime = new Date(
        currentTime.setHours(currentTime.getHours() + mtOffset - offsetInHours)
      )
      const dayOfWeek = mtTime.getDay()
      setIsSlurpTownOpen(dayOfWeek === 0 || dayOfWeek === 6)
    }

    updateSlurpTownStatus()
    const interval = setInterval(() => {
      setSlurpTime(new Date())
      updateSlurpTownStatus()
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className="slurp-page">
      <div className="slurp-container">
        <h1 className="slurp-time">
          {slurpTime.toLocaleTimeString('en-US', {
            timeZone: 'America/Denver',
          })}
        </h1>
        <h1 className="slurp-town-message">
          {isSlurpTownOpen ? 'SLURP TOWN IS OPEN' : 'SLURP TOWN IS CLOSED'}
        </h1>
        <span className="slurp-stream">enter the slurp stream</span>
      </div>
    </div>
  )
}

export default SlurpTown
