// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.updates {
  overflow: hidden;
  height: 560px;
}

.update-container {
  margin: 0 auto;
  width: 150px;
  position: relative;
}

.update-line {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 20px auto 5px auto;
  text-align: left;
}

.update-date {
  font-size: 12px;
}

.update-icon {
  margin: 0 5px;
  align-self: flex-end;
}

.gloogo-name {
  color: white;
}

.update-content {
  text-align: left;
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/components/blog/updates/updates-list/updates-list.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,aAAA;AACF;;AAEA;EACE,cAAA;EACA,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,0BAAA;EACA,gBAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,aAAA;EACA,oBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,gBAAA;EACA,cAAA;AACF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
