import React from 'react'
import './email-links.scss'

import alert from '../../../media/contact/alert.png'
import link from '../../../media/contact/link.png'
import tree from '../../../media/contact/tree.png'
import mailbox from '../../../media/contact/mailbox.png'
import puterMail from '../../../media/contact/puter-mail.gif'
import mailCat from '../../../media/contact/mail-cat.gif'
import mailboxHand from '../../../media/contact/mailbox-hand.gif'
import mailCarry from '../../../media/contact/mail-carry.gif'
import emailMe from '../../../media/contact/email-me.gif'
import msnButterfly from '../../../media/contact/msn-butterfly.png'
import discord from '../../../media/contact/discord.png'

const EmailLinks = () => {
  return (
    <div className="email-links-container">
      <div className="upper-email-images">
        <div className="image-button-row">
          <a href="https://xkcd.com/1247/" target="_blank">
            <img src={alert} />
          </a>
          <img src={link} />
          <a href="https://xkcd.com/835/" target="_blank">
            <img src={tree} />
          </a>
          <a href="https://xkcd.com/96/" target="_blank">
            <img src={mailbox} />
          </a>
        </div>
        <img className="puter-mail" src={puterMail} />
      </div>
      <span className="email-header">e-mail</span>
      <div className="gif-row">
        <img src={mailCat} />
        <img src={mailboxHand} />
        <img className="smaller-gif" src={mailCarry} />
      </div>
      <a href="mailto:webmaster@cooperplanet.com">
        <img className="email-me" src={emailMe} />
      </a>
      <span className="email-header">chat</span>
      <a href="https://escargot.chat/" target="_blank">
        <img className="msn-butterfly" src={msnButterfly} />
      </a>
      <span className="chat-link">msn</span>
      <a href="https://discord.com/users/165332337985323008" target="_blank">
        <img className="discord-icon" src={discord} />
      </a>
      <span className="chat-link">discord</span>
    </div>
  )
}

export default EmailLinks
