import React from 'react'
import './friend-box.scss'

import siteBadge from '../../../media/badges/gloogo-v1.gif'

const linkHtml = `<a href="https://gloogo.io/" target="_blank" rel="noopener"><img src="https://gloogo.io/images/gloogo.gif" /></a>`

const FriendBox = () => {
  return (
    <div className="friend-box-container">
      <h3 className="friends-header">friends & mutuals</h3>
      <div className="friends-container">
        <a
          className="friend-link"
          href="https://cottage.thecozy.cat/"
          target="_blank"
        >
          <img className="friend-badge" src="https://i.imgur.com/niPc0Aw.gif" />
        </a>
      </div>
      <h3 className="friends-header">add me</h3>
      <img className="friend-badge" src={siteBadge} />
      <textarea className="badge-html" value={linkHtml} />
    </div>
  )
}

export default FriendBox
