// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-the-site {
  font-size: 20px;
  width: 610px;
  margin: 15px auto;
  text-align: left;
}

.always-under-construction {
  color: #e0ca00;
  font-size: 22px;
  text-align: left;
  display: block;
  margin: 0 auto;
  width: 610px;
}

.lil-construction-guy {
  vertical-align: top;
  margin-left: 5px;
}

.about-the-webmaster-section {
  width: 610px;
  margin: 20px auto;
}

.catscape-icon-container {
  position: relative;
  width: 95px;
  height: 95px;
  border: 2px solid #0075ff;
}

.catscape-navigator-static {
  position: absolute;
  width: 100%;
}

.catscape-navigator-static:hover {
  opacity: 0;
}

.catscape-navigator-active {
  width: 100%;
}

.asl {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -230px;
  text-align: left;
}

.asl span {
  margin: 5px 0;
  font-size: 20px;
}

.asl span strong {
  color: white;
}

.about-the-webmaster {
  font-size: 20px;
  width: 610px;
  margin: 5px auto;
  text-align: left;
}

.about-the-webmaster strong {
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/about/about.scss","webpack://./src/styles/_colors.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,YAAA;EACA,iBAAA;EACA,gBAAA;AADF;;AAIA;EACE,cCVO;EDWP,eAAA;EACA,gBAAA;EACA,cAAA;EACA,cAAA;EACA,YAAA;AADF;;AAIA;EACE,mBAAA;EACA,gBAAA;AADF;;AAIA;EACE,YAAA;EACA,iBAAA;AADF;;AAIA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,yBAAA;AADF;;AAIA;EACE,kBAAA;EACA,WAAA;AADF;;AAIA;EACE,UAAA;AADF;;AAIA;EACE,WAAA;AADF;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,6BAAA;EACA,kBAAA;EACA,MAAA;EACA,SAAA;EACA,aAAA;EACA,gBAAA;AADF;;AAIA;EACE,aAAA;EACA,eAAA;AADF;;AAIA;EACE,YAAA;AADF;;AAIA;EACE,eAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;AADF;;AAIA;EACE,YAAA;AADF","sourcesContent":["@import '../../styles/colors';\n\n.about-the-site {\n  font-size: 20px;\n  width: 610px;\n  margin: 15px auto;\n  text-align: left;\n}\n\n.always-under-construction {\n  color: $yellow;\n  font-size: 22px;\n  text-align: left;\n  display: block;\n  margin: 0 auto;\n  width: 610px;\n}\n\n.lil-construction-guy {\n  vertical-align: top;\n  margin-left: 5px;\n}\n\n.about-the-webmaster-section {\n  width: 610px;\n  margin: 20px auto;\n}\n\n.catscape-icon-container {\n  position: relative;\n  width: 95px;\n  height: 95px;\n  border: 2px solid $blue;\n}\n\n.catscape-navigator-static {\n  position: absolute;\n  width: 100%;\n}\n\n.catscape-navigator-static:hover {\n  opacity: 0;\n}\n\n.catscape-navigator-active {\n  width: 100%;\n}\n\n.asl {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-evenly;\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  right: -230px;\n  text-align: left;\n}\n\n.asl span {\n  margin: 5px 0;\n  font-size: 20px;\n}\n\n.asl span strong {\n  color: white;\n}\n\n.about-the-webmaster {\n  font-size: 20px;\n  width: 610px;\n  margin: 5px auto;\n  text-align: left;\n}\n\n.about-the-webmaster strong {\n  color: white;\n}\n","$yellow: #e0ca00;\n$blue: #0075ff;\n$darkblue: #0c318f;\n$pink: #bf00b7;\n$gray: #959da5;\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
