// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../media/nintendo-humming-e.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: humming-e;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.kk-slider-container {
  height: 215px;
  width: 210px;
  background-color: black;
  border: 2px solid #bf00b7;
  position: absolute;
  bottom: -2px;
  right: -235px;
  box-sizing: border-box;
}

.thank-you {
  font-family: humming-e;
  font-size: 16px;
  color: #959da5;
  margin: -15px auto;
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/components/about/kk-slider/kk-slider.scss","webpack://./src/styles/_colors.scss"],"names":[],"mappings":"AACA;EACE,sBAAA;EACA,4CAAA;AAAF;AAGA;EACE,aAAA;EACA,YAAA;EACA,uBAAA;EACA,yBAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;AADF;;AAIA;EACE,sBAAA;EACA,eAAA;EACA,cChBK;EDiBL,kBAAA;EACA,cAAA;AADF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
