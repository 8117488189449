// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slurp-container {
  display: flex;
  margin-top: 100px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.slurp-time {
  color: white;
}

.slurp-town-message {
  color: white;
  font-size: 40px;
  margin-top: 5px;
}

.slurp-stream {
  position: absolute;
  bottom: 10px;
  right: 15px;
  cursor: not-allowed !important;
  user-select: none;
}`, "",{"version":3,"sources":["webpack://./src/components/slurp-town/slurp-town.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,iBAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;AACF;;AAGA;EACE,YAAA;AAAF;;AAGA;EACE,YAAA;EACA,eAAA;EACA,eAAA;AAAF;;AAGA;EACE,kBAAA;EACA,YAAA;EACA,WAAA;EACA,8BAAA;EACA,iBAAA;AAAF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
