import React, { useState, useEffect } from 'react'
import axios from 'axios'
import LeftAbout from './left-about/left-about'
import RightAbout from './right-about/right-about'
import HatcheryBox from './hatchery-box/hatchery-box'
import NavBox from '../common/nav-box/nav-box'
import NowPlaying from './now-playing/now-playing'
import PostsOverview from '../common/posts-overview/posts-overview'
import './home.scss'

import monkey1 from '../../media/home/monkey-1.gif'
import monkey2 from '../../media/home/monkey-2.gif'
import badge1 from '../../media/badges/mspaint.gif'
import badge2 from '../../media/badges/gloogo-v1.gif'
import badge3 from '../../media/badges/gba.gif'

const Home = () => {
  const [posts, setPosts] = useState([])

  useEffect(() => {
    const getPosts = async () => {
      try {
        const response = await axios.get('/api/blog/posts')
        if (response.data) setPosts(response.data)
      } catch (error) {}
    }
    getPosts()
  }, [])

  return (
    <div className="home-page">
      <div className="header-container">
        <h1 className="header">gloogo</h1>
        <h2 className="subheader">
          <img
            alt="Construction monkey 1"
            className="construction-monkey-1"
            src={monkey1}
          />
          under construction
          <img
            alt="Construction monkey 2"
            className="construction-monkey-2"
            src={monkey2}
          />
        </h2>
      </div>
      <div className="main-content-container border-gradient">
        <NavBox />
        <LeftAbout />
        <RightAbout />
        <HatcheryBox />
        <div className="upper-section">
          <span className="news-section">
            <h3 className="section-header">news</h3>
            <p className="news-text">
              In Philly now, site has been stagnant. <br />
              <br />
              Getting adjusted at work and making minor tweaks to old projects.
              Nothing huge coming too soon.
            </p>
          </span>
          <span className="divider" />
          <span className="now-section">
            <h3 className="section-header">now</h3>
            <NowPlaying />
          </span>
        </div>
        {posts.length > 0 && (
          <div className="posts-section">
            <PostsOverview isOnHomePage={true} posts={posts} />
          </div>
        )}
      </div>
      <div className="badges-container">
        <img alt="MS Paint" className="badge" src={badge1} />
        <img alt="Gloogo v1" className="badge" src={badge2} />
        <img alt="GBA" className="badge" src={badge3} />
      </div>
    </div>
  )
}

export default Home
