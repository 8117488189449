// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.update-container {
  margin: 0 auto;
  width: 150px;
}

.update-header {
  margin-top: 18px;
  display: flex;
  justify-content: center;
}

.username {
  color: white;
  font-size: 14px;
  margin-right: 3px;
}

.home-update-icon {
  margin-right: 3px;
}

.update-time {
  font-size: 12px;
}

.update-text {
  margin-top: 8px;
  display: inline-block;
}`, "",{"version":3,"sources":["webpack://./src/components/common/status-update/status-update.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,YAAA;AACF;;AAEA;EACE,gBAAA;EACA,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,YAAA;EACA,eAAA;EACA,iBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,eAAA;EACA,qBAAA;AACF","sourcesContent":[".update-container {\n  margin: 0 auto;\n  width: 150px;\n}\n\n.update-header {\n  margin-top: 18px;\n  display: flex;\n  justify-content: center;\n}\n\n.username {\n  color: white;\n  font-size: 14px;\n  margin-right: 3px;\n}\n\n.home-update-icon {\n  margin-right: 3px;\n}\n\n.update-time {\n  font-size: 12px;\n}\n\n.update-text {\n  margin-top: 8px;\n  display: inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
