// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.guestbook-form-field {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.guestbook-form-upper-fields {
  display: flex;
  align-items: left;
  flex-grow: 1;
  justify-content: space-between;
}

.guestbook-name-input {
  width: 85%;
}

.guestbook-form {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 70%;
  margin: 15px auto 0 auto;
  text-align: left;
}

.guestbook-form span {
  font-size: 22px;
}

.guestbook-form input {
  margin-bottom: 10px;
  border: 2px solid #0075ff;
  height: 20px;
}

.guestbook-form textarea {
  height: 80px;
  resize: none;
  outline: none;
  border: 2px solid #0075ff;
  margin-top: -10pxl;
}

.guestbook-form button {
  border: none;
  cursor: pointer;
  align-self: flex-end;
  background-color: #0075ff;
  color: black;
  padding: 0 25px;
  font-size: 34px;
  margin-top: 10px;
}

.guestbook-form button:hover {
  color: white;
}

.guestbook-error-message {
  color: #e0ca00;
  font-size: 12px !important;
  position: absolute;
  left: 0;
  bottom: -20px;
}

.guestbook-success-message {
  color: limegreen;
  font-size: 14px !important;
  position: absolute;
  left: 0;
  bottom: -20px;
}`, "",{"version":3,"sources":["webpack://./src/components/guestbook/guestbook.scss","webpack://./src/styles/_colors.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;AADF;;AAIA;EACE,aAAA;EACA,iBAAA;EACA,YAAA;EACA,8BAAA;AADF;;AAIA;EACE,UAAA;AADF;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,UAAA;EACA,wBAAA;EACA,gBAAA;AADF;;AAIA;EACE,eAAA;AADF;;AAIA;EACE,mBAAA;EACA,yBAAA;EACA,YAAA;AADF;;AAIA;EACE,YAAA;EACA,YAAA;EACA,aAAA;EACA,yBAAA;EACA,kBAAA;AADF;;AAIA;EACE,YAAA;EACA,eAAA;EACA,oBAAA;EACA,yBCjDK;EDkDL,YAAA;EACA,eAAA;EACA,eAAA;EACA,gBAAA;AADF;;AAIA;EACE,YAAA;AADF;;AAIA;EACE,cC9DO;ED+DP,0BAAA;EACA,kBAAA;EACA,OAAA;EACA,aAAA;AADF;;AAIA;EACE,gBAAA;EACA,0BAAA;EACA,kBAAA;EACA,OAAA;EACA,aAAA;AADF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
