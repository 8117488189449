// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.updates-container {
  height: 742px;
  width: 210px;
  background-color: black;
  border: 2px solid #e0ca00;
  position: absolute;
  top: -2px;
  right: -235px;
  box-sizing: border-box;
  padding-top: 11px;
}

.updates-header {
  color: white;
  display: block;
  width: 100%;
  text-align: center;
  font-size: 24px;
  margin: 5px auto 0 auto;
  font-weight: 100;
}

.footer-blinkies {
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-around;
}

.footer-blinkies img {
  width: 150px;
  margin: 2px auto;
}`, "",{"version":3,"sources":["webpack://./src/components/blog/updates/updates.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,YAAA;EACA,uBAAA;EACA,yBAAA;EACA,kBAAA;EACA,SAAA;EACA,aAAA;EACA,sBAAA;EACA,iBAAA;AADF;;AAIA;EACE,YAAA;EACA,cAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;EACA,uBAAA;EACA,gBAAA;AADF;;AAIA;EACE,kBAAA;EACA,YAAA;EACA,OAAA;EACA,QAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,6BAAA;AADF;;AAIA;EACE,YAAA;EACA,gBAAA;AADF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
