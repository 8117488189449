// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wwoctober-page {
  padding: 20px;
}

.wwoctober-header {
  color: white;
  font-size: 40px;
  margin-top: 5px;
  display: inline;
}

.wwoctober-link {
  margin-left: 40px;
  display: inline;
}

.day-item {
  font-size: 20px;
}
.day-item::marker {
  color: #959da5;
}
.day-item a {
  color: inherit;
  text-decoration: none;
}
.day-item a:hover {
  color: #e0ca00;
}

.wwoctober-link {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/wwoctober/wwoctober.scss","webpack://./src/styles/_colors.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;AADJ;;AAIA;EACE,YAAA;EACA,eAAA;EACA,eAAA;EACA,eAAA;AADF;;AAIA;EACE,iBAAA;EACA,eAAA;AADF;;AAIA;EACE,eAAA;AADF;AAGE;EACE,cClBG;ADiBP;AAIE;EACE,cAAA;EACA,qBAAA;AAFJ;AAII;EACE,cC9BG;AD4BT;;AAOA;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EACA,eAAA;AAJF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
