// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.updates {
  overflow: hidden;
  height: 560px;
}

.update-container {
  margin: 0 auto;
  width: 150px;
  position: relative;
}

.update-line {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 20px auto 5px auto;
  text-align: left;
}

.update-date {
  font-size: 12px;
}

.update-icon {
  margin: 0 5px;
  align-self: flex-end;
}

.gloogo-name {
  color: white;
}

.update-content {
  text-align: left;
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/components/blog/updates/updates-list/updates-list.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,aAAA;AACF;;AAEA;EACE,cAAA;EACA,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,0BAAA;EACA,gBAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,aAAA;EACA,oBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,gBAAA;EACA,cAAA;AACF","sourcesContent":[".updates {\n  overflow: hidden;\n  height: 560px;\n}\n\n.update-container {\n  margin: 0 auto;\n  width: 150px;\n  position: relative;\n}\n\n.update-line {\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n  margin: 20px auto 5px auto;\n  text-align: left;\n}\n\n.update-date {\n  font-size: 12px;\n}\n\n.update-icon {\n  margin: 0 5px;\n  align-self: flex-end;\n}\n\n.gloogo-name {\n  color: white;\n}\n\n.update-content {\n  text-align: left;\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
