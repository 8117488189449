import egg1 from '../../media/eggs/blue-flecked.gif'
import egg2 from '../../media/eggs/brown-ripple.gif'
import egg3 from '../../media/eggs/green-flecked.gif'
import egg4 from '../../media/eggs/orange-ripple.gif'
import egg5 from '../../media/eggs/pink-spots.gif'
import egg6 from '../../media/eggs/purple-spots.gif'
import egg7 from '../../media/eggs/red-stripe.gif'
import egg8 from '../../media/eggs/fire-egg.gif'
import egg9 from '../../media/eggs/blue-stripe.gif'
import egg10 from '../../media/eggs/hopping-egg.gif'

export const eggImages = {
  'blue-flecked': egg1,
  'brown-ripple': egg2,
  'green-flecked': egg3,
  'orange-ripple': egg4,
  'pink-spots': egg5,
  'purple-spots': egg6,
  'red-stripe': egg7,
  'fire-egg': egg8,
  'blue-stripe': egg9,
  'hopping-egg': egg10,
}

export const getHatcherySubmessage = enteredCode => {
  if (enteredCode)
    return 'The egg will eventually hatch, but until then, feel free to keep it on your site!'
  return "If you're an early visitor with a number and code, enter them here to adopt an egg."
}

export const singleSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    border: '2px solid #e0ca00',
    borderRadius: '0%',
    boxShadow: 'none',
    textAlign: 'left',
    height: '34px',
    width: '200px',
    backgroundColor: 'black',
    marginTop: '13px',
    marginLeft: '20px',
    fontSize: '18px',
  }),
  option: (provided, state) => ({
    ...provided,
    '&:hover': {
      color: '#e0ca00',
    },
    backgroundColor: state.isSelected ? '#0075ff' : 'black',
    color: 'white',
    cursor: 'pointer',
    textAlign: 'left',
  }),
  placeholder: provided => ({
    ...provided,
    color: '#959da5',
  }),
  singleValue: provided => ({
    ...provided,
    color: '#959da5',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: '#e0ca00',
    cursor: 'pointer',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  menu: provided => ({
    ...provided,
    borderRadius: '0%',
    maxWidth: '200px',
    backgroundColor: 'black',
    color: 'white',
  }),
  menuList: provided => ({
    ...provided,
    border: '2px solid #e0ca00',
    borderRadius: '0%',
    paddingTop: '0',
    paddingBottom: '0',
    maxWidth: '200px',
    color: 'white',
  }),
}
