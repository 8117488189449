import React from 'react'
import NavBox from '../common/nav-box/nav-box'
import BlinkyBox from './blinky-box/blinky-box'
import FeaturedList from './featured-list/featured-list'
import KKSlider from './kk-slider/kk-slider'

import lilConstructionGuy from '../../media/about/lil-construction-guy.png'
import catscape from '../../media/about/catscape.png'
import catscapeLoader from '../../media/about/catscape-loader.gif'
import './about.scss'

const About = () => {
  return (
    <div className="secondary-page">
      <h1 className="page-header">about</h1>
      <div className="page-content-container">
        <NavBox />
        <BlinkyBox />
        <FeaturedList />
        <KKSlider />
        <h3 className="page-section-header">about the site</h3>
        <p className="about-the-site">
          This is my first time hosting a personal website since 2008. This site
          is made mostly for fun, but also as a tribute to the brutally honest
          early days of the web.
          <br /> <br />
          Most of the images on this site are extracted from archived GeoCities
          pages. If you know of any missing credits please let me know and I'll
          be happy to credit the original creator.
        </p>
        <span className="always-under-construction">
          This site is always under construction!
          <img className="lil-construction-guy" src={lilConstructionGuy} />
        </span>
        <h3 className="page-section-header">about the webmaster</h3>
        <div className="about-the-webmaster-section">
          <div className="catscape-icon-container">
            <img className="catscape-navigator-static" src={catscape} />
            <img className="catscape-navigator-active" src={catscapeLoader} />
            <div className="asl">
              <span>
                <strong>Name: </strong> Jordan
              </span>
              <span>
                <strong>A/S/L: </strong> 29, M, USA
              </span>
              <span>
                <strong>Neopet: </strong>
                Starving
              </span>
            </div>
          </div>
        </div>
        <p className="about-the-webmaster">
          <strong>What I do:</strong> I'm a software developer who enjoys making
          things. I have an inexplicable fascination with early internet
          technology, and I'm always hunting for nostalgia.
          <br /> <br />
          Hopefully this site is only the beginning of my projects making their
          way onto the world wide web.
        </p>
      </div>
    </div>
  )
}

export default About
