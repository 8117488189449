import React, { useState, useEffect } from 'react'
import axios from 'axios'
import './photo-box.scss'

const PhotoBox = () => {
  const [recentPhotos, setRecentPhotos] = useState([])

  useEffect(() => {
    const getRecentPhotos = async () => {
      try {
        const response = await axios.get('/api/blog/recent-photos?limit=3')
        if (response.data) setRecentPhotos(response.data)
      } catch (error) {}
    }
    getRecentPhotos()
  }, [])

  const renderPhotos = () => {
    if (!recentPhotos) return null
    return recentPhotos.map(photo => {
      return (
        <img
          className="photo-box-photo"
          src={photo.thumbnailUrl || photo.url}
          alt={photo.description || 'photo'}
          key={photo.id}
        />
      )
    })
  }

  return (
    <div className="photo-box-container">
      <h3 className="photo-box-header">photos</h3>
      <div className="photo-box-photo-container">{renderPhotos()}</div>
    </div>
  )
}

export default PhotoBox
