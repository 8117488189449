import React, { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import About from './components/about/about'
import Home from './components/home/home'
import Hatchery from './components/hatchery/hatchery'
import Contact from './components/contact/contact'
import Links from './components/links/links'
import Guestbook from './components/guestbook/guestbook'
import Blog from './components/blog/blog'
import SlurpTown from './components/slurp-town/slurp-town'
import Anniversary from './components/anniversary/anniversary'
import WWOctober from './components/wwoctober/wwoctober'
import { useCursor } from './context/cursor-context'
import './app.scss'

import Nature from './components/wwoctober/1-nature/nature'
import CSS from './components/wwoctober/2-css/css'

const App = () => {
  const { cursor, setCursor } = useCursor()

  useEffect(() => {
    const storedCursor = localStorage.getItem('cursor')
    if (storedCursor) setCursor(storedCursor)
  }, [])

  return (
    <div className={`background cursor-${cursor}`}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/links" element={<Links />} />
        <Route path="/guestbook" element={<Guestbook />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:postSlug" element={<Blog />} />
        <Route path="/hatchery" element={<Hatchery />} />
        <Route path="/slurp-town" element={<SlurpTown />} />
        <Route path="/anniversary" element={<Anniversary />} />
        <Route path="/wwoctober" element={<WWOctober />} />
        <Route path="/wwoctober/nature" element={<Nature />} />
        <Route path="/wwoctober/css" element={<CSS />} />
      </Routes>
    </div>
  )
}

export default App
