// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.now-section {
  text-align: center;
}

.now-text {
  color: #959da5;
  font-size: 18px;
  font-weight: 100;
  text-align: left;
  margin: 12px auto;
}

.now-text strong {
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/home/now-playing/now-playing.scss","webpack://./src/styles/_colors.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;AADF;;AAIA;EACE,cCHK;EDIL,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,iBAAA;AADF;;AAIA;EACE,YAAA;AADF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
