// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.coming-soon-links {
  font-size: 24px;
  display: block;
  text-align: center;
  margin: 85px auto;
  width: 450px;
}

.link-list {
  margin: 20px auto;
  width: 610px;
  text-align: left;
}

.link-item {
  display: block;
  font-size: 22px;
  margin-bottom: 7px;
}

.world-gif {
  position: absolute;
  bottom: 20px;
  right: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/links/links.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,cAAA;EACA,kBAAA;EACA,iBAAA;EACA,YAAA;AACF;;AAEA;EACE,iBAAA;EACA,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,YAAA;EACA,WAAA;AACF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
