// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cursor-table-border {
  margin: 25px auto 0 auto;
}

.cursor-container {
  width: 140px;
  height: 145px;
}

.cursor-header {
  color: white;
  font-size: 14px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  display: inline-block;
}

.clear-cursor {
  color: #0075ff;
  font-size: 14px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  display: inline-block;
}

.clear-cursor:hover {
  color: #bf00b7;
}

.cursor-selection {
  width: 110px;
  display: flex;
  justify-content: space-around;
  margin: 10px auto 0 auto;
  flex-wrap: wrap;
}

.cursor {
  margin-bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/home/right-about/cursor-select/cursor-select.scss","webpack://./src/styles/_colors.scss"],"names":[],"mappings":"AAEA;EACE,wBAAA;AADF;;AAIA;EACE,YAAA;EACA,aAAA;AADF;;AAIA;EACE,YAAA;EACA,eAAA;EACA,kBAAA;EACA,WAAA;EACA,cAAA;EACA,qBAAA;AADF;;AAIA;EACE,cCpBK;EDqBL,eAAA;EACA,kBAAA;EACA,WAAA;EACA,cAAA;EACA,qBAAA;AADF;;AAIA;EACE,cC3BK;AD0BP;;AAIA;EACE,YAAA;EACA,aAAA;EACA,6BAAA;EACA,wBAAA;EACA,eAAA;AADF;;AAIA;EACE,kBAAA;AADF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
