// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blinky-box-container {
  height: 458px;
  width: 210px;
  background-color: black;
  border: 2px solid #bf00b7;
  position: absolute;
  bottom: -2px;
  left: -235px;
  box-sizing: border-box;
  padding-top: 2px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-evenly;
}

.blinky-box-container img {
  width: 150px;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/components/about/blinky-box/blinky-box.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,YAAA;EACA,uBAAA;EACA,yBAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;EACA,sBAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,6BAAA;AADF;;AAIA;EACE,YAAA;EACA,cAAA;AADF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
