import React from 'react'
import './falling-leaf.scss'
import fallingLeaf from '../../media/falling-leaf.gif'

const FallingLeaf = ({ start, delay }) => {
  const style = {
    left: `${start}%`,
    animationDelay: `${delay}s`,
  }

  return (
    <img
      src={fallingLeaf}
      className="falling-leaf"
      style={style}
      alt="falling leaf"
    />
  )
}

export default FallingLeaf
