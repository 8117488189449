import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import EggForm from './egg-form/egg-form'
import EggSuccess from './egg-success/egg-success'
import { eggImages, getHatcherySubmessage } from './hatchery.logic'
import './hatchery.scss'

const Hatchery = () => {
  const [eggs, setEggs] = useState([])
  const [hasValidCode, setHasValidCode] = useState(false)
  const [code, setCode] = useState('')
  const [number, setNumber] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [selectedEgg, setSelectedEgg] = useState(null)
  const [newEgg, setNewEgg] = useState(null)

  useEffect(() => {
    const getEggs = async () => {
      const eggData = await axios.get('/api/eggs')
      if (!eggData.data) setErrorMessage('There was an error loading the eggs.')
      setEggs(eggData.data)
      const existingCode = localStorage.getItem('code')
      if (!existingCode) return
      const codeData = JSON.parse(existingCode)
      setCode(codeData.code)
      setNumber(codeData.id)
    }
    getEggs()
  }, [newEgg])

  const renderEggs = () => {
    return eggs.map((egg, i) => {
      return (
        <div className="egg" key={i} style={{ top: egg.y, left: egg.x }}>
          <img
            className="egg-gif"
            onClick={() => handleSelectEgg(egg)}
            src={eggImages[egg.imageSlug]}
          />
          {egg.website ? (
            <a
              className="egg-website"
              href={egg.website}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="egg-name">{egg.name}</span>
            </a>
          ) : (
            <span className="egg-name">{egg.name}</span>
          )}
        </div>
      )
    })
  }

  const handleSelectEgg = egg => {
    if (!hasValidCode) return
    if (egg.name) return setErrorMessage('That egg has been claimed.')
    setSelectedEgg(egg)
  }

  const handleCodeChange = value => {
    setCode(value)
  }

  const handleNumberChange = value => {
    if (value.length > 3) return
    setNumber(value)
  }

  const handleSubmitCode = async (codeText, codeNumber) => {
    if (!codeText || !codeNumber)
      return setErrorMessage('Please enter a code and number.')
    try {
      const codeData = await axios.get(
        `/api/code?code=${codeText}&number=${codeNumber}`
      )
      if (!codeData.data)
        return setErrorMessage('Invalid code/number combination.')
      else {
        setHasValidCode(true)
        setErrorMessage('')
      }
    } catch (error) {
      if (error.response.data.message)
        return setErrorMessage(error.response.data.message)
      else return setErrorMessage('Something went wrong.')
    }
  }

  const handleFormBack = () => {
    setSelectedEgg(null)
    setErrorMessage('')
  }

  const handleFormReset = () => {
    setNewEgg(null)
    setSelectedEgg(null)
    setHasValidCode(false)
    setCode('')
    setNumber('')
    setErrorMessage('')
  }

  const handleSubmitForm = async formData => {
    try {
      const eggData = await axios.put('/api/eggs', {
        ...formData,
        number,
        code,
      })
      setNewEgg(eggData.data.newEgg)
      setSelectedEgg(null)
    } catch (error) {
      if (error.response.data.message) {
        setErrorMessage(error.response.data.message)
        setSelectedEgg(null)
      } else {
        setErrorMessage('Something went wrong.')
        setSelectedEgg(null)
      }
    }
  }

  return (
    <div className="secondary-page">
      <h1 className="hatchery-header">hatchery</h1>
      <div className="hatchery-content-container">
        <span className="home-link">
          <Link to="/">{`< home`}</Link>
        </span>
        <span className="hatchery-message">
          These eggs are going to hatch soon.
        </span>
        {newEgg ? (
          <EggSuccess egg={newEgg} handleFormReset={handleFormReset} />
        ) : selectedEgg ? (
          <EggForm
            selectedEgg={selectedEgg}
            handleFormBack={handleFormBack}
            submitForm={handleSubmitForm}
          />
        ) : (
          <>
            <span className="hatchery-submessage">
              {getHatcherySubmessage(hasValidCode)}
            </span>
            {hasValidCode ? (
              <div className="hatchery-welcome-back-container">
                <span className="hatchery-welcome-message">Welcome back!</span>
                <span className="hatchery-select-message">
                  Go ahead and pick an egg.
                </span>
                {errorMessage ? (
                  <span className="error-message">{errorMessage}</span>
                ) : null}
              </div>
            ) : (
              <>
                <div className="hatchery-input-container">
                  <input
                    className="code-input"
                    value={code}
                    onChange={e => handleCodeChange(e.target.value)}
                    onKeyDown={e => {
                      if (e.key === 'Enter') handleSubmitCode(code, number)
                    }}
                    placeholder="Code..."
                  />
                  <input
                    className="number-input"
                    value={number}
                    onChange={e => handleNumberChange(e.target.value)}
                    onKeyDown={e => {
                      if (e.key === 'Enter') handleSubmitCode(code, number)
                    }}
                    placeholder="#"
                  />
                  <button
                    className="ok-button"
                    onClick={() => handleSubmitCode(code, number)}
                  >
                    OK
                  </button>
                  {errorMessage ? (
                    <span className="error-message">{errorMessage}</span>
                  ) : null}
                </div>
                <span className="forgot-code-text">
                  Forgot your code/number? <Link to="/contact">Email me.</Link>
                </span>
              </>
            )}
            <div className="egg-carton">{renderEggs()}</div>
          </>
        )}
      </div>
    </div>
  )
}

export default Hatchery
