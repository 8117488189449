// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.posts-by-date {
  text-align: left;
  margin: 15px 70px 0 70px;
  max-height: 500px;
  overflow-y: auto;
}

.post-list {
  list-style: none;
  padding: 0;
  margin-top: 2px;
}

.star-bullet {
  display: inline;
  width: 26px;
  object-fit: contain;
  align-self: flex-start;
  margin-top: 5px;
}

.post-container {
  display: flex;
}

.post-title {
  font-size: 22px;
  font-weight: 100;
  margin-top: 0;
  margin-bottom: 8px;
  display: inline-block;
}

.post-date {
  color: white;
  font-size: 18px;
  font-weight: 100;
  margin-bottom: 0;
}

.more-posts-link {
  color: blue;
  text-align: left;
  margin-left: 70px;
  font-size: 22px;
}

.no-posts {
  text-align: center;
  margin-top: 100px;
  font-size: 22px;
  font-weight: 100;
}`, "",{"version":3,"sources":["webpack://./src/components/common/posts-overview/posts-overview.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,wBAAA;EACA,iBAAA;EACA,gBAAA;AACF;;AAEA;EACE,gBAAA;EACA,UAAA;EACA,eAAA;AACF;;AAEA;EACE,eAAA;EACA,WAAA;EACA,mBAAA;EACA,sBAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,aAAA;EACA,kBAAA;EACA,qBAAA;AACF;;AAEA;EACE,YAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AACF;;AAEA;EACE,WAAA;EACA,gBAAA;EACA,iBAAA;EACA,eAAA;AACF;;AAEA;EACE,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,gBAAA;AACF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
