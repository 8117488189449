import React, { createContext, useState, useContext } from 'react'
const CursorContext = createContext(null)

export const CursorProvider = props => {
  const [cursor, setCursor] = useState(null)
  return (
    <CursorContext.Provider value={{ cursor, setCursor }}>
      {props.children}
    </CursorContext.Provider>
  )
}

export const useCursor = () => {
  return useContext(CursorContext)
}
