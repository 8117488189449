import React from 'react'
import { Link } from 'react-router-dom'
import './wwoctober.scss'

const days = ['Nature', 'CSS']

const WWOctober = () => {
  return (
    <div className="wwoctober-page">
      <h1 className="wwoctober-header">Weird Web October</h1>
      <ol>
        {days.map((day, i) => {
          return (
            <li key={i} className="day-item">
              <Link to={`/wwoctober/${day.toLowerCase()}`}>{day}</Link>
            </li>
          )
        })}
      </ol>
      <span className="wwoctober-link">
        <a href="https://weirdweboctober.website/" target="_blank">
          What is this?
        </a>
      </span>
    </div>
  )
}

export default WWOctober
