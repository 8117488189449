import React from 'react'
import NavBox from '../common/nav-box/nav-box'
import FriendBox from './friend-box/friend-box'
import './links.scss'

import world from '../../media/links/world.gif'

const coolThings = [
  {
    title: 'PictoChat',
    url: 'https://pict.chat/',
    description: 'nintendo ds pictochat on the web',
  },
  {
    title: 'Animalese.js',
    url: 'http://acedio.github.io/animalese.js/',
    description: 'animal crossing text-to-speech',
  },
  {
    title: 'Windows 93',
    url: 'http://www.windows93.net/',
  },
  {
    title: 'Kindness Rocks',
    url: 'https://pixelrevival.xyz/kindnessrocks/',
    description: 'adoptable pet rocks',
  },
  {
    title: 'Life Universe',
    url: 'https://oimo.io/works/life/',
    description: "conway's game of life, but bigger",
  },
  {
    title: 'GifCities',
    url: 'https://gifcities.org/',
    description: 'geocities gif search',
  },
  {
    title: 'xkcd',
    url: 'https://xkcd.com/',
    description: 'webcomic',
  },
]

const LinkItem = ({ title, url, description }) => {
  return (
    <span className="link-item">
      <a href={url} target="_blank">
        {title}
      </a>
      {description ? ` - ${description}` : null}
    </span>
  )
}

const Links = () => {
  return (
    <div className="secondary-page">
      <h1 className="page-header">links</h1>
      <div className="page-content-container">
        <NavBox />
        <FriendBox />
        <h3 className="page-section-header">my stuff</h3>
        <span className="coming-soon-links">
          currently moving hosting servers, links coming back soon
        </span>
        <h3 className="page-section-header">cool things</h3>
        <div className="link-list">
          {coolThings.map((link, i) => (
            <LinkItem {...link} key={i} />
          ))}
        </div>
        <a href="https://cooperplanet.com/" target="_blank">
          <img className="world-gif" src={world} alt="spinning world" />
        </a>
      </div>
    </div>
  )
}

export default Links
