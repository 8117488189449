import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom'
import CursorSelect from './cursor-select/cursor-select'
import StatusUpdate from '../../common/status-update/status-update'
import './right-about.scss'

const RightAbout = () => {
  const [visitors, setVisitors] = useState(0)

  useEffect(() => {
    const getVisitors = async () => {
      const visited = Cookies.get('visited')
      const response = await axios.put(`/api/visitors?visited=${visited}`)
      setVisitors(response.data.visitorCount)
      Cookies.set('visited', true, { expires: 30 })
    }
    getVisitors()
  }, [])

  return (
    <div className="right-about-container">
      <span className="welcome-header">welcome</span>
      <table
        className="visitor-table-border"
        bgcolor="grey"
        cellPadding="3"
        border="2"
      >
        <tbody>
          <tr>
            <td bgcolor="black">
              <span className="visitor-count">{`${visitors} Visitors`}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <CursorSelect />
      <StatusUpdate />
      <span className="past-updates-link">
        <Link to="/blog">{`past updates >`}</Link>
      </span>
    </div>
  )
}

export default RightAbout
