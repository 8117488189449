// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.right-about-container {
  height: 440px;
  width: 210px;
  background-color: black;
  border: 2px solid #bf00b7;
  position: absolute;
  top: -2px;
  right: -235px;
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 20px;
}

.welcome-header {
  color: white;
  display: block;
  width: 100%;
  text-align: center;
  font-size: 28px;
  margin: 0 auto;
}

.visitor-table-border {
  margin: 10px auto;
}

.visitor-count {
  color: lime;
}

.past-updates-link {
  text-align: right;
  position: absolute;
  bottom: 15px;
  right: 20px;
  width: 110px;
}`, "",{"version":3,"sources":["webpack://./src/components/home/right-about/right-about.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,YAAA;EACA,uBAAA;EACA,yBAAA;EACA,kBAAA;EACA,SAAA;EACA,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,oBAAA;AADF;;AAIA;EACE,YAAA;EACA,cAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;EACA,cAAA;AADF;;AAIA;EACE,iBAAA;AADF;;AAIA;EACE,WAAA;AADF;;AAIA;EACE,iBAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;AADF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
