import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import useNumberOfItemsToShow from '../../../hooks/use-number-of-items-to-show'
import { formatDate } from '../../../utils'
import './posts-overview.scss'

import star from '../../../media/slowstar.gif'

const PostsOverview = ({ posts, isOnHomePage, showBlogPost }) => {
  const itemRefs = useRef([])
  const containerHeight = isOnHomePage ? 300 : 0
  const numberOfPostsToShow = useNumberOfItemsToShow(
    itemRefs,
    containerHeight,
    20
  )

  const setRef = element => {
    if (element) {
      itemRefs.current.push(element)
    }
  }

  const renderPosts = () => {
    const postsByDate = {}

    posts.forEach(post => {
      const date = formatDate(post.postDate)
      if (!postsByDate[date]) postsByDate[date] = []
      postsByDate[date].push(post)
    })

    const postElements = []

    for (const date in postsByDate) {
      const postsToRender = postsByDate[date]
      const postElementsForDate = postsToRender.map((post, i) => (
        <div className="post-container" key={`${post.title}-${i}`}>
          <img className="star-bullet" src={star} />
          {post.description ? (
            <p className="post-title">
              <Link
                className="title-link"
                to={`/blog/${post.urlSlug}`}
                onClick={showBlogPost ? () => showBlogPost(post) : () => {}}
              >
                {post.title}
              </Link>
              {' -'} {post.description}
            </p>
          ) : (
            <p className="post-title">
              <Link
                className="title-link"
                to={`/blog/${post.urlSlug}`}
                onClick={showBlogPost ? () => showBlogPost(post) : () => {}}
              >
                {post.title}
              </Link>
            </p>
          )}
        </div>
      ))
      const postElement = (
        <div key={date} ref={setRef}>
          <span className="post-date">{date}</span>
          <ul className="post-list">{postElementsForDate}</ul>
        </div>
      )
      postElements.push(postElement)
    }
    if (isOnHomePage) return postElements.slice(0, numberOfPostsToShow)
    else return postElements
  }

  return (
    <div className="posts-container">
      <h3 className="section-header">posts</h3>
      <>
        <div className="posts-by-date">{renderPosts()}</div>
        {isOnHomePage && (
          <p className="more-posts-link">
            <Link to="/blog">{`more posts >`}</Link>
          </p>
        )}
      </>
    </div>
  )
}

export default PostsOverview
