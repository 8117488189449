import React from 'react'
import './featured-list.scss'

import frog from '../../../media/about/frog.gif'

import stardew from '../../../media/badges/stardew-valley.gif'
import win95 from '../../../media/badges/win-95.gif'
import internetExplorer from '../../../media/badges/msoft-ie.png'
import geocities from '../../../media/badges/geocities.gif'
import koolaid from '../../../media/badges/koolaid.png'
import shockwave from '../../../media/badges/shockwave.png'
import noWebp from '../../../media/badges/no-webp.gif'
import hotmail from '../../../media/badges/hotmail.png'
import bob from '../../../media/badges/bob-power.gif'
import catscape from '../../../media/badges/catscape.gif'

const badges = [
  stardew,
  win95,
  internetExplorer,
  geocities,
  koolaid,
  shockwave,
  noWebp,
  hotmail,
  bob,
  catscape,
]

const listTitle = 'top disney movies'
const listItems = [
  'Robin Hood',
  "The Emperor's New Groove",
  'The Incredibles',
  'Lion King',
  'Toy Story',
]

const FeaturedList = () => {
  return (
    <div className="featured-list-container">
      <h3 className="featured-list-title">{listTitle}</h3>
      <ol className="featured-list">
        {listItems.map((item, i) => {
          return (
            <li key={i} className="featured-list-item">
              {item}
            </li>
          )
        })}
      </ol>
      <img className="frog" src={frog} />
      <span className="lists-submessage">
        visit my{' '}
        <a href="" className="unfinished">
          lists
        </a>{' '}
        page to see more
      </span>
      <div className="badges-container">
        {badges.map((badge, i) => {
          return <img key={i} className="badge" src={badge} />
        })}
      </div>
    </div>
  )
}

export default FeaturedList
