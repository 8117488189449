// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.anni-input-container {
  display: flex;
  margin-top: 100px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.anni-input-title {
  font-size: 30px;
}

.anni-input {
  margin: 0 10px;
  font-size: 20px;
  text-align: center;
}

.anni-button {
  margin-top: 10px;
}

.anni-list-container {
  margin-left: 40px;
}

.anni-list {
  font-size: 18px;
}

.anni-list-item {
  margin: 5px 0;
}

@media (max-width: 600px) {
  .anni-list-title {
    font-size: 20px;
  }
  .anni-list-container {
    margin-left: 0;
  }
  .anni-list-item {
    margin: 10px 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/anniversary/anniversary.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,iBAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;AACF;;AAGA;EACE,eAAA;AAAF;;AAKA;EACI,cAAA;EACA,eAAA;EACA,kBAAA;AAFJ;;AAKA;EACE,gBAAA;AAFF;;AAKA;EACE,iBAAA;AAFF;;AAKA;EACE,eAAA;AAFF;;AAKA;EACE,aAAA;AAFF;;AAKA;EACE;IACE,eAAA;EAFF;EAKA;IACE,cAAA;EAHF;EAMA;IACE,cAAA;EAJF;AACF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
