// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.updates-container {
  height: 742px;
  width: 210px;
  background-color: black;
  border: 2px solid #e0ca00;
  position: absolute;
  top: -2px;
  right: -235px;
  box-sizing: border-box;
  padding-top: 11px;
}

.updates-header {
  color: white;
  display: block;
  width: 100%;
  text-align: center;
  font-size: 24px;
  margin: 5px auto 0 auto;
  font-weight: 100;
}

.footer-blinkies {
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-around;
}

.footer-blinkies img {
  width: 150px;
  margin: 2px auto;
}`, "",{"version":3,"sources":["webpack://./src/components/blog/updates/updates.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,YAAA;EACA,uBAAA;EACA,yBAAA;EACA,kBAAA;EACA,SAAA;EACA,aAAA;EACA,sBAAA;EACA,iBAAA;AADF;;AAIA;EACE,YAAA;EACA,cAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;EACA,uBAAA;EACA,gBAAA;AADF;;AAIA;EACE,kBAAA;EACA,YAAA;EACA,OAAA;EACA,QAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,6BAAA;AADF;;AAIA;EACE,YAAA;EACA,gBAAA;AADF","sourcesContent":["@import '../../../styles/colors';\n\n.updates-container {\n  height: 742px;\n  width: 210px;\n  background-color: black;\n  border: 2px solid $yellow;\n  position: absolute;\n  top: -2px;\n  right: -235px;\n  box-sizing: border-box;\n  padding-top: 11px;\n}\n\n.updates-header {\n  color: white;\n  display: block;\n  width: 100%;\n  text-align: center;\n  font-size: 24px;\n  margin: 5px auto 0 auto;\n  font-weight: 100;\n}\n\n.footer-blinkies {\n  position: absolute;\n  bottom: 15px;\n  left: 0;\n  right: 0;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n  justify-content: space-around;\n}\n\n.footer-blinkies img {\n  width: 150px;\n  margin: 2px auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
