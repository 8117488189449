// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hatchery-box-container {
  height: 210px;
  width: 210px;
  background-color: black;
  border: 2px solid #0c318f;
  position: absolute;
  top: 460px;
  right: -235px;
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 20px;
}

.hatchery-box-header {
  color: white;
  display: block;
  width: 100%;
  text-align: center;
  font-size: 24px;
  margin: 0 auto;
}

.hatchery-box-text {
  width: 160px;
  font-size: 18px;
  display: block;
  margin: 10px auto 0 auto;
}

.home-eggs {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px auto 0 auto;
}

.home-egg {
  width: 30px;
  margin: 0 2px;
}`, "",{"version":3,"sources":["webpack://./src/components/home/hatchery-box/hatchery-box.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,YAAA;EACA,uBAAA;EACA,yBAAA;EACA,kBAAA;EACA,UAAA;EACA,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,oBAAA;AADF;;AAIA;EACE,YAAA;EACA,cAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;EACA,cAAA;AADF;;AAIA;EACE,YAAA;EACA,eAAA;EACA,cAAA;EACA,wBAAA;AADF;;AAIA;EACE,WAAA;EACA,aAAA;EACA,uBAAA;EACA,wBAAA;AADF;;AAIA;EACE,WAAA;EACA,aAAA;AADF","sourcesContent":["@import '../../../styles/colors';\n\n.hatchery-box-container {\n  height: 210px;\n  width: 210px;\n  background-color: black;\n  border: 2px solid $darkblue;\n  position: absolute;\n  top: 460px;\n  right: -235px;\n  box-sizing: border-box;\n  padding-top: 10px;\n  padding-bottom: 20px;\n}\n\n.hatchery-box-header {\n  color: white;\n  display: block;\n  width: 100%;\n  text-align: center;\n  font-size: 24px;\n  margin: 0 auto;\n}\n\n.hatchery-box-text {\n  width: 160px;\n  font-size: 18px;\n  display: block;\n  margin: 10px auto 0 auto;\n}\n\n.home-eggs {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  margin: 10px auto 0 auto;\n}\n\n.home-egg {\n  width: 30px;\n  margin: 0 2px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
