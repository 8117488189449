// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hatchery-header {
  color: white;
  font-size: 48px;
  width: 100%;
  margin-top: 35px;
  margin-bottom: 30px;
  font-weight: 100;
  text-align: center;
}

.hatchery-content-container {
  width: 980px;
  height: 780px;
  background-color: black;
  margin: 20px auto 0 auto;
  position: relative;
  border: 2px solid #e0ca00;
  text-align: center;
}

.home-link {
  font-size: 24px;
  position: absolute;
  top: 15px;
  left: 30px;
}

.hatchery-message {
  width: 346px;
  display: block;
  margin: 50px auto 0 auto;
  font-size: 18px;
}

.hatchery-submessage {
  color: white;
  font-size: 18px;
  margin-top: 20px;
  display: inline-block;
  width: 346px;
}

.hatchery-input-container {
  margin-top: 35px;
  position: relative;
}

.code-input {
  background-color: black;
  border: 2px solid #e0ca00;
  width: 180px;
  height: 36px;
  color: white;
  font-size: 24px;
  padding-left: 8px;
}

.number-input {
  background-color: black;
  margin-left: 15px;
  border: 2px solid #e0ca00;
  width: 36px;
  height: 36px;
  color: white;
  font-size: 24px;
  padding-left: 8px;
}

input:focus {
  outline: none;
}

.ok-button {
  background-color: #131418;
  color: #959da5;
  vertical-align: top;
  margin-top: 5px;
  margin-left: 15px;
  font-size: 22px;
}

.error-message {
  color: red;
  font-size: 12px;
  position: absolute;
  bottom: -40px;
  left: 0;
  right: 0;
}

.hatchery-welcome-back-container {
  margin-top: 35px;
  position: relative;
}

.hatchery-welcome-message {
  color: white;
  font-size: 32px;
  display: block;
}

.hatchery-select-message {
  color: white;
  font-size: 18px;
  margin-top: 6px;
  display: inline-block;
}

.forgot-code-text {
  margin-top: 8px;
  font-size: 12px;
  display: inline-block;
}

.egg-carton {
  width: 550px;
  height: 300px;
  margin: 50px auto 0 auto;
  position: relative;
}

.egg {
  width: 76px;
  position: absolute;
}

.egg-gif {
  width: 76px;
  vertical-align: bottom;
}

.egg-name {
  margin-top: -3px;
  display: inline-block;
  font-size: 12px;
  vertical-align: top;
}`, "",{"version":3,"sources":["webpack://./src/components/hatchery/hatchery.scss","webpack://./src/styles/_colors.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,eAAA;EACA,WAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;AADF;;AAIA;EACE,YAAA;EACA,aAAA;EACA,uBAAA;EACA,wBAAA;EACA,kBAAA;EACA,yBAAA;EACA,kBAAA;AADF;;AAIA;EACE,eAAA;EACA,kBAAA;EACA,SAAA;EACA,UAAA;AADF;;AAIA;EACE,YAAA;EACA,cAAA;EACA,wBAAA;EACA,eAAA;AADF;;AAIA;EACE,YAAA;EACA,eAAA;EACA,gBAAA;EACA,qBAAA;EACA,YAAA;AADF;;AAIA;EACE,gBAAA;EACA,kBAAA;AADF;;AAIA;EACE,uBAAA;EACA,yBAAA;EACA,YAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;EACA,iBAAA;AADF;;AAIA;EACE,uBAAA;EACA,iBAAA;EACA,yBAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;EACA,iBAAA;AADF;;AAIA;EACE,aAAA;AADF;;AAIA;EACE,yBAAA;EACA,cCxEK;EDyEL,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,eAAA;AADF;;AAIA;EACE,UAAA;EACA,eAAA;EACA,kBAAA;EACA,aAAA;EACA,OAAA;EACA,QAAA;AADF;;AAIA;EACE,gBAAA;EACA,kBAAA;AADF;;AAIA;EACE,YAAA;EACA,eAAA;EACA,cAAA;AADF;;AAIA;EACE,YAAA;EACA,eAAA;EACA,eAAA;EACA,qBAAA;AADF;;AAIA;EACE,eAAA;EACA,eAAA;EACA,qBAAA;AADF;;AAIA;EACE,YAAA;EACA,aAAA;EACA,wBAAA;EACA,kBAAA;AADF;;AAIA;EACE,WAAA;EACA,kBAAA;AADF;;AAIA;EACE,WAAA;EACA,sBAAA;AADF;;AAIA;EACE,gBAAA;EACA,qBAAA;EACA,eAAA;EACA,mBAAA;AADF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
