// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.egg-success-image {
  width: 110px;
  vertical-align: bottom;
  display: inline-block;
}

.egg-success-header {
  color: white;
  font-weight: 100;
  font-size: 28px;
}

.egg-success-image {
  display: block;
  margin: 0 auto;
}

.egg-success-message {
  display: block;
  width: 350px;
  margin: 20px auto;
  font-size: 18px;
}

.notify-message {
  display: block;
  width: 350px;
  margin: 15px auto;
  font-size: 18px;
}

.egg-html {
  margin-top: 10px;
  font-size: 12px;
  font-family: unset !important;
  width: 200px;
  height: 60px;
}

.form-reset-button {
  background-color: black;
  margin: 30px auto;
  display: block;
  font-size: 18px;
  color: #959da5;
  width: 100px;
}`, "",{"version":3,"sources":["webpack://./src/components/hatchery/egg-success/egg-success.scss","webpack://./src/styles/_colors.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,sBAAA;EACA,qBAAA;AADF;;AAIA;EACE,YAAA;EACA,gBAAA;EACA,eAAA;AADF;;AAIA;EACE,cAAA;EACA,cAAA;AADF;;AAIA;EACE,cAAA;EACA,YAAA;EACA,iBAAA;EACA,eAAA;AADF;;AAIA;EACE,cAAA;EACA,YAAA;EACA,iBAAA;EACA,eAAA;AADF;;AAIA;EACE,gBAAA;EACA,eAAA;EACA,6BAAA;EACA,YAAA;EACA,YAAA;AADF;;AAIA;EACE,uBAAA;EACA,iBAAA;EACA,cAAA;EACA,eAAA;EACA,cC1CK;ED2CL,YAAA;AADF","sourcesContent":["@import '../../../styles/colors';\n\n.egg-success-image {\n  width: 110px;\n  vertical-align: bottom;\n  display: inline-block;\n}\n\n.egg-success-header {\n  color: white;\n  font-weight: 100;\n  font-size: 28px;\n}\n\n.egg-success-image {\n  display: block;\n  margin: 0 auto;\n}\n\n.egg-success-message {\n  display: block;\n  width: 350px;\n  margin: 20px auto;\n  font-size: 18px;\n}\n\n.notify-message {\n  display: block;\n  width: 350px;\n  margin: 15px auto;\n  font-size: 18px;\n}\n\n.egg-html {\n  margin-top: 10px;\n  font-size: 12px;\n  font-family: unset !important;\n  width: 200px;\n  height: 60px;\n}\n\n.form-reset-button {\n  background-color: black;\n  margin: 30px auto;\n  display: block;\n  font-size: 18px;\n  color: $gray;\n  width: 100px;\n}\n","$yellow: #e0ca00;\n$blue: #0075ff;\n$darkblue: #0c318f;\n$pink: #bf00b7;\n$gray: #959da5;\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
