import React from 'react'
import './kk-slider.scss'

import kkSlider from '../../../media/about/kk-slider.gif'

const KKSlider = () => {
  return (
    <div className="kk-slider-container">
      <img className="kk-slider" src={kkSlider} />
      <span className="thank-you">Thanks for visiting!</span>
    </div>
  )
}

export default KKSlider
