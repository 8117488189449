import React, { useRef } from 'react'
import useNumberOfItemsToShow from '../../../../hooks/use-number-of-items-to-show'
import { timeAgo } from '../../../../utils'
import './updates-list.scss'

import pcUpdate from '../../../../media/pc-update.png'
import checkIn from '../../../../media/check-in.png'

const UpdatesList = ({ updates }) => {
  const itemRefs = useRef([])
  const numberOfUpdates = useNumberOfItemsToShow(itemRefs, 560, 20)

  const setRef = element => {
    if (element) {
      itemRefs.current.push(element)
    }
  }

  return (
    <div className="updates">
      {updates &&
        updates
          .map((update, i) => {
            return (
              <div className="update-container" key={i} ref={setRef}>
                <div className="update-line">
                  <span className="gloogo-name">gloogo</span>
                  <img
                    className="update-icon"
                    src={update.updateType === 'update' ? pcUpdate : checkIn}
                  />
                  <span className="update-date">
                    {timeAgo(update.postDate)}
                  </span>
                </div>
                <span className="update-content">
                  {update.updateType === 'update'
                    ? update.content
                    : `checked in at ${update.content}`}
                </span>
              </div>
            )
          })
          .slice(0, numberOfUpdates)}
    </div>
  )
}

export default UpdatesList
