// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.photo-box-container {
  height: 458px;
  width: 210px;
  background-color: black;
  border: 2px solid #e0ca00;
  position: absolute;
  bottom: -2px;
  left: -235px;
  box-sizing: border-box;
}

.photo-box-header {
  color: white;
  display: block;
  width: 100%;
  text-align: center;
  font-size: 24px;
  margin: 5px auto 0 auto;
  font-weight: 100;
}

.photo-box-photo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 420px;
}

.photo-box-photo {
  width: 125px;
  height: 125px;
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/components/blog/photo-box/photo-box.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,YAAA;EACA,uBAAA;EACA,yBAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;EACA,sBAAA;AADF;;AAIA;EACE,YAAA;EACA,cAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;EACA,uBAAA;EACA,gBAAA;AADF;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,6BAAA;EACA,aAAA;AADF;;AAIA;EACE,YAAA;EACA,aAAA;EACA,iBAAA;AADF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
