import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useParams, useNavigate } from 'react-router-dom'
import NavBox from '../common/nav-box/nav-box'
import PhotoBox from './photo-box/photo-box'
import PostsOverview from '../common/posts-overview/posts-overview'
import Updates from './updates/updates'
import './blog.scss'
import Post from './post/post'

const Blog = () => {
  const [posts, setPosts] = useState([])
  const [openPost, setOpenPost] = useState(null)
  const { postSlug } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    const getPost = async () => {
      if (postSlug) {
        try {
          const response = await axios.get(`/api/blog/${postSlug}`)
          if (response.data) setOpenPost(response.data)
          else {
            resetOpenPost()
          }
        } catch (error) {
          resetOpenPost()
        }
      } else {
        try {
          const response = await axios.get('/api/blog/posts')
          if (response.data) setPosts(response.data)
        } catch (error) {}
      }
    }
    getPost()
  }, [postSlug])

  const resetOpenPost = () => {
    navigate('/blog')
    setOpenPost(null)
  }

  return (
    <div className="secondary-page">
      <h1 className="page-header">blog</h1>
      <div className="page-content-container">
        <NavBox />
        <Updates />
        <PhotoBox />
        {openPost ? (
          <Post post={openPost} resetOpenPost={resetOpenPost} />
        ) : (
          <>
            <div className="blog-margin">
              {posts.length > 0 && (
                <PostsOverview
                  posts={posts}
                  showBlogPost={post => setOpenPost(post)}
                />
              )}
            </div>
            <div className="comments-coming-soon">
              Comments and reactions coming soon...
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Blog
