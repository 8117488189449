import React from 'react'
import { createRoot } from 'react-dom/client'

import App from './app'
import { BrowserRouter as Router } from 'react-router-dom'
import { CursorProvider } from './context/cursor-context'

const container = document.getElementById('app')
const root = createRoot(container)
root.render(
  <Router>
    <CursorProvider>
      <App />
    </CursorProvider>
  </Router>
)
