import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import NavBox from '../common/nav-box/nav-box'
import EmailLinks from './email-links/email-links'
import './contact.scss'
import axios from 'axios'

const Contact = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [website, setWebsite] = useState('')
  const [message, setMessage] = useState('')
  const [statusMessage, setStatusMessage] = useState('')
  const [status, setStatus] = useState('')

  const handleSubmit = async () => {
    if (!name || !message) {
      setStatus('error')
      setStatusMessage('Please include your name and a message.')
      return
    }
    const postData = {
      name,
      email,
      website,
      message,
    }
    try {
      const response = await axios.post('/api/message', {
        postData,
      })

      if (!response.data.id) {
        setStatus('error')
        setStatusMessage('There was a problem sending the message.')
        return
      }

      clearForm()
      setStatus('success')
      setStatusMessage('Message sent!')
    } catch (error) {
      setStatus('error')
      setStatusMessage('There was a problem sending the message.')
      return
    }
  }

  const clearForm = () => {
    setName('')
    setEmail('')
    setWebsite('')
    setMessage('')
  }

  const handleNameChange = value => {
    if (value.length > 40) return
    setName(value)
  }

  const handleEmailChange = value => {
    if (value.length > 255) return
    setEmail(value)
  }

  const handleWebsiteChange = value => {
    if (value.length > 360) return
    setWebsite(value)
  }

  const handleMessageChange = value => {
    if (value.length > 1000) return
    setMessage(value)
  }

  return (
    <div className="secondary-page">
      <h1 className="page-header">contact</h1>
      <div className="page-content-container">
        <NavBox />
        <EmailLinks />
        <h3 className="page-section-header">message me</h3>
        <span className="email-subheader">
          use this form to send me a message
        </span>
        <div className="email-form">
          <span>name</span>
          <input
            value={name}
            onChange={e => handleNameChange(e.target.value)}
          />
          <span>e-mail</span>
          <input
            value={email}
            onChange={e => handleEmailChange(e.target.value)}
          />
          <span>website</span>
          <input
            value={website}
            onChange={e => handleWebsiteChange(e.target.value)}
          />
          <span>message</span>
          <textarea
            value={message}
            onChange={e => handleMessageChange(e.target.value)}
          />
          <span className="privacy-note">
            all information will remain private
            {statusMessage && (
              <span className={`contact-${status}-message`}>
                {statusMessage}
              </span>
            )}
          </span>
          <button className="email-submit-button" onClick={handleSubmit}>
            send
          </button>
        </div>
        <p className="contact-me-text">
          Want to leave a public message that stays on the site? Checkout the{' '}
          <Link to="/guestbook">guestbook</Link> page instead!
          <br />
          <br />
          You can also contact me using the links to the right. My MSN address
          is <strong>jordan@escargot.chat</strong>
        </p>
      </div>
    </div>
  )
}

export default Contact
