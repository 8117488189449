// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.poll-box-container {
  height: 458px;
  width: 210px;
  background-color: black;
  border: 2px solid #bf00b7;
  position: absolute;
  bottom: -2px;
  left: -235px;
  box-sizing: border-box;
}

.poll-box-header {
  color: white;
  display: block;
  width: 100%;
  text-align: center;
  font-size: 24px;
  margin: 10px auto 0 auto;
  font-weight: 100;
}

.moogle-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 7px auto;
  padding: 0 20px;
}

.poll-box-text {
  color: #e0ca00;
  font-size: 18px;
  margin: 10px auto;
  font-weight: 100;
  width: 170px;
  display: inline-block;
}

.poll-options {
  width: 75%;
  display: block;
  margin: 0 auto;
}

.poll-option {
  text-align: left;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.poll-option-unvoted {
  text-align: left;
  margin-bottom: 5px;
}

.option-label {
  margin-left: 8px;
}

.poll-option-name {
  text-align: left;
  max-width: 70%;
}

.poll-option-votes {
  text-align: right;
  margin: 0 5px;
  text-wrap: none;
  white-space: nowrap;
  align-self: flex-end;
}

.user-vote {
  color: limegreen;
}

.vote-button {
  background-color: #0075ff;
  color: black;
  padding: 1px 5px;
  font-weight: 100;
  border: none;
  outline: none;
  font-size: 18px;
  margin-top: 5px;
}

.daily-question {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
}

.daily-question-buttons {
  margin-top: -7px;
  display: flex;
  justify-content: space-evenly;
  padding: 0 20px;
}

.a-splode-text {
  color: #e0ca00;
  font-size: 18px;
  margin: 10px auto;
  font-weight: 100;
  width: 180px;
  display: inline-block;
}

.torticon-container {
  position: relative;
  width: 100%;
}

.torticon-static {
  position: absolute;
  width: 35px;
  background-color: black;
}

.torticon-static:hover {
  opacity: 0;
}

.torticon-active {
  width: 35px;
  background-color: black;
}

.pet-images {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.pet-images img {
  max-width: 135px;
}`, "",{"version":3,"sources":["webpack://./src/components/guestbook/poll-box/poll-box.scss","webpack://./src/styles/_colors.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,YAAA;EACA,uBAAA;EACA,yBAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;EACA,sBAAA;AADF;;AAIA;EACE,YAAA;EACA,cAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;EACA,wBAAA;EACA,gBAAA;AADF;;AAIA;EACE,aAAA;EACA,mBAAA;EACA,6BAAA;EACA,gBAAA;EACA,eAAA;AADF;;AAIA;EACE,cChCO;EDiCP,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,YAAA;EACA,qBAAA;AADF;;AAIA;EACE,UAAA;EACA,cAAA;EACA,cAAA;AADF;;AAIA;EACE,gBAAA;EACA,kBAAA;EACA,aAAA;EACA,8BAAA;AADF;;AAIA;EACE,gBAAA;EACA,kBAAA;AADF;;AAIA;EACE,gBAAA;AADF;;AAIA;EACE,gBAAA;EACA,cAAA;AADF;;AAIA;EACE,iBAAA;EACA,aAAA;EACA,eAAA;EACA,mBAAA;EACA,oBAAA;AADF;;AAIA;EACE,gBAAA;AADF;;AAIA;EACE,yBC/EK;EDgFL,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,YAAA;EACA,aAAA;EACA,eAAA;EACA,eAAA;AADF;;AAIA;EACE,kBAAA;EACA,YAAA;EACA,OAAA;EACA,QAAA;AADF;;AAIA;EACE,gBAAA;EACA,aAAA;EACA,6BAAA;EACA,eAAA;AADF;;AAIA;EACE,cCzGO;ED0GP,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,YAAA;EACA,qBAAA;AADF;;AAIA;EACE,kBAAA;EACA,WAAA;AADF;;AAIA;EACE,kBAAA;EACA,WAAA;EACA,uBAAA;AADF;;AAIA;EACE,UAAA;AADF;;AAIA;EACE,WAAA;EACA,uBAAA;AADF;;AAIA;EACE,gBAAA;EACA,aAAA;EACA,uBAAA;AADF;;AAIA;EACE,gBAAA;AADF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
