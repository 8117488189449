import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { timeAgo } from '../../../utils'
import './status-update.scss'

import pcUpdate from '../../../media/pc-update.png'
import checkIn from '../../../media/check-in.png'

const StatusUpdate = () => {
  const [update, setUpdate] = useState(null)

  useEffect(() => {
    const getRecentUpdate = async () => {
      try {
        const response = await axios.get('/api/blog/recent-update')
        setUpdate(response.data)
      } catch (error) {
        console.log(error)
      }
    }
    getRecentUpdate()
  }, [])

  const updateIcon =
    update && update.updateType === 'update' ? pcUpdate : checkIn

  if (!update) return null

  return (
    <div className="update-container">
      <div className="update-header">
        <span className="username">gloogo</span>
        <img className="home-update-icon" src={updateIcon} />
        <span className="update-time">{timeAgo(update.postDate)}</span>
      </div>
      <span className="update-text">
        {update.updateType === 'update'
          ? update.content
          : `checked in at ${update.content}`}
      </span>
    </div>
  )
}

export default StatusUpdate
