import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Markdown from 'react-markdown'
import { formatDate } from '../../../utils'
import './post.scss'

import star from '../../../media/slowstar.gif'

const Post = ({ post, resetOpenPost }) => {
  const [postContent, setPostContent] = useState(null)

  useEffect(() => {
    const getPostContent = async () => {
      try {
        const response = await axios.get(
          `https://raw.githubusercontent.com/jordanfuzz/blog/master/posts/${post.urlSlug}.md`
        )
        setPostContent(response.data)
      } catch (error) {}
    }
    getPostContent()
  }, [post])

  return (
    <div className="full-post-container">
      <div className="blog-upper-header">
        <span className="back-link" onClick={resetOpenPost}>
          {'<'} back
        </span>
        <span className="full-post-date">{formatDate(post?.postDate)}</span>
      </div>
      <h1 className="full-post-title">
        <img className="big-star-bullet" src={star} />
        {post?.title}
      </h1>
      <div className="full-post-content">
        {postContent ? (
          <Markdown>{postContent}</Markdown>
        ) : (
          <p>
            Looks like this page is either still being updated or it was
            deleted...
          </p>
        )}
      </div>
    </div>
  )
}

export default Post
