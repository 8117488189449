import React from 'react'
import './comments.scss'

const Comments = ({ comments }) => {
  const renderComments = () => {
    return comments.map(comment => {
      return (
        <div className="comment" key={comment.id}>
          <div className="comment-header">
            <span className="comment-name">
              {comment.website ? (
                <a href={comment.website} target="_blank">
                  {comment.name}
                </a>
              ) : (
                comment.name
              )}
            </span>
            <span className="comment-date">{comment.createdAt}</span>
          </div>
          <div className="comment-message">{comment.message}</div>
        </div>
      )
    })
  }

  return (
    <div className="comments-container">
      {!comments.length && <div className="no-comments">no comments</div>}
      {!!comments.length && renderComments()}
    </div>
  )
}

export default Comments
