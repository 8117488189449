// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.email-links-container {
  height: 470px;
  width: 210px;
  background-color: black;
  border: 2px solid #bf00b7;
  position: absolute;
  bottom: -2px;
  right: -235px;
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 20px;
}

.upper-email-images {
  position: relative;
}

.puter-mail {
  position: absolute;
  top: -105px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.image-button-row {
  position: absolute;
  top: -65px;
  left: -1px;
  width: 101%;
}

.image-button-row img {
  height: 53px;
}

.email-header {
  color: white;
  display: block;
  width: 100%;
  text-align: center;
  font-size: 28px;
  margin: 0 auto;
  position: relative;
}

.gif-row {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 10px;
  padding: 0 15px;
}

.gif-row img:first-child {
  margin-right: 10px;
}

.smaller-gif {
  height: 44px;
  width: auto;
  vertical-align: top;
}

.email-me {
  margin: 20px auto 30px auto;
  display: block;
}

.msn-butterfly {
  display: block;
  margin: 20px auto 0 auto;
  width: 62px;
}

.chat-link {
  display: block;
  margin: 0 auto;
  text-align: center;
  font-size: 24px;
}

.discord-icon {
  display: block;
  margin: 20px auto 0 auto;
  width: 62px;
}`, "",{"version":3,"sources":["webpack://./src/components/contact/email-links/email-links.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,YAAA;EACA,uBAAA;EACA,yBAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,oBAAA;AADF;;AAIA;EACE,kBAAA;AADF;;AAIA;EACE,kBAAA;EACA,WAAA;EACA,OAAA;EACA,QAAA;EACA,cAAA;AADF;;AAIA;EACE,kBAAA;EACA,UAAA;EACA,UAAA;EACA,WAAA;AADF;;AAIA;EACE,YAAA;AADF;;AAIA;EACE,YAAA;EACA,cAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;EACA,cAAA;EACA,kBAAA;AADF;;AAIA;EACE,aAAA;EACA,6BAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;AADF;;AAIA;EACE,kBAAA;AADF;;AAIA;EACE,YAAA;EACA,WAAA;EACA,mBAAA;AADF;;AAIA;EACE,2BAAA;EACA,cAAA;AADF;;AAIA;EACE,cAAA;EACA,wBAAA;EACA,WAAA;AADF;;AAIA;EACE,cAAA;EACA,cAAA;EACA,kBAAA;EACA,eAAA;AADF;;AAIA;EACE,cAAA;EACA,wBAAA;EACA,WAAA;AADF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
