export const timeAgo = date => {
  const dateObj = new Date(date)
  const seconds = Math.floor((new Date() - dateObj) / 1000)
  const minutes = Math.floor(seconds / 60)

  if (minutes === 0) {
    return 'just now'
  } else if (minutes < 60) {
    return `${minutes} min ago`
  } else {
    const hours = Math.floor(minutes / 60)
    if (hours === 1) {
      return '1 hr ago'
    } else if (hours < 24) {
      return `${hours} hrs ago`
    } else {
      const days = Math.floor(hours / 24)
      if (days === 1) {
        return '1 day ago'
      } else if (days < 7) {
        return `${days} days ago`
      } else {
        const weeks = Math.floor(days / 7)
        if (weeks === 1) {
          return '1 week ago'
        } else if (weeks < 52) {
          return `${weeks} weeks ago`
        } else {
          return 'a while ago'
        }
      }
    }
  }
}

export const formatDate = timestamp => {
  const date = new Date(timestamp)
  const year = date.getFullYear().toString().substring(-2)
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  return `${month}.${day}.${year}`
}
