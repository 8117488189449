import React from 'react'
import { Link } from 'react-router-dom'
import './nav-box.scss'

const NavBox = () => {
  return (
    <div className="nav-container">
      <Link to="/">
        <span className="nav-item">home</span>
      </Link>
      <Link to="/about">
        <span className="nav-item">about</span>
      </Link>
      <Link to="/contact">
        <span className="nav-item">contact</span>
      </Link>
      <Link to="/links">
        <span className="nav-item">links</span>
      </Link>
      {/* <span className="nav-item">projects</span> */}
      <Link to="/guestbook">
        <span className="nav-item">guestbook</span>
      </Link>
      <Link to="/blog">
        <span className="nav-item">blog</span>
      </Link>
    </div>
  )
}

export default NavBox
