import React, { useState } from 'react'
import './css.scss'

const tree = `
     ccssSScc
  CSCSCSCSScCs cScc
 cSSSSCCSScCCscscXScC
CsssXCSCC cCs cCccCSccs
    SCcC  /s c sccCccsS
      \\\\\\//  /ccXCS
        \\\\\\////
         |||/\\
         |||\\/
         |||||
   .....//||||\\....
`

const CSS = () => {
  const [styleOption, setStyleOption] = useState('font-size')
  const [fontSize, setFontSize] = useState(20)
  const [borderColor, setBorderColor] = useState('white')
  const [borderWidth, setBorderWidth] = useState(1)
  const [borderStyle, setBorderStyle] = useState('solid')
  const [borderRadius, setBorderRadius] = useState(1)
  const [fruit, setFruit] = useState('🍒')

  const treeArray = tree.split('')

  const handleCssValueChange = value => {
    switch (styleOption) {
      case 'font-size':
        setFontSize(Number(value))
        break
      case 'border-color':
        setBorderColor(value)
        break
      case 'border-width':
        setBorderWidth(Number(value))
        break
      case 'border-style':
        setBorderStyle(value)
        break
      case 'border-radius':
        setBorderRadius(Number(value))
        break
      default:
        setFruit(value)
    }
  }

  const getActiveStyleOption = () => {
    switch (styleOption) {
      case 'font-size':
        return fontSize
      case 'border-color':
        return borderColor
      case 'border-width':
        return borderWidth
      case 'border-style':
        return borderStyle
      case 'border-radius':
        return borderRadius
      default:
        return fruit
    }
  }

  return (
    <div className="wwoctober-page">
      <div>
        <span>Set</span>
        <select onChange={e => setStyleOption(e.target.value)}>
          <option value="font-size">font-size</option>
          <option value="border-color">border-color</option>
          <option value="border-width">border-width</option>
          <option value="border-style">border-style</option>
          <option value="border-radius">border-radius</option>
          <option value="🍒">🍒</option>
        </select>
        <span>to</span>
        <input
          type="text"
          value={getActiveStyleOption()}
          onChange={e => handleCssValueChange(e.target.value)}
        />
      </div>
      <div className="ascii-container">
        <div
          className="tree-border"
          style={{
            borderColor,
            borderStyle,
            borderWidth,
            borderRadius: `${borderRadius}%`,
          }}
        >
          <pre>
            <code className="ascii-tree" style={{ fontSize: `${fontSize}px` }}>
              {treeArray.map(char => {
                return char === 'X' ? fruit : char
              })}
            </code>
          </pre>
        </div>
      </div>
    </div>
  )
}

export default CSS
