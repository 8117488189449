// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.falling-leaf {
  position: absolute;
  top: 0;
  animation: fall 10s linear infinite;
  z-index: 10;
}

@keyframes fall {
  to {
    transform: translateY(100vh);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/anniversary/falling-leaf.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,MAAA;EACA,mCAAA;EACA,WAAA;AACF;;AAEA;EACE;IACE,4BAAA;EACF;AACF","sourcesContent":[".falling-leaf {\n  position: absolute;\n  top: 0;\n  animation: fall 10s linear infinite;\n  z-index: 10;\n}\n\n@keyframes fall {\n  to {\n    transform: translateY(100vh);\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
