export const list = [
  "Your laugh is the best. ",
  "You ask me about how I'm doing.",
  "You stay up late to talk to me.",
  "You send shrimp emojis when you're happy.",
  "You make jokes all the time.",
  "You genuinely care about doing the right thing.",
  "You want to do well in your career.",
  "You're a wonderful artist.",
  "You laugh at my dumb jokes.",
  "You are modest in your actions.",
  "You are modest in your dress.",
  "You want my family to like you.",
  "You are excellent at drawing.",
  "You like feeding animals at the park.",
  "You are very mentally mature.",
  "You can survive getting 3 hours of sleep.",
  "You have a desire to be better than you are.",
  "You like learning.",
  "You can cook.",
  "You have a smile that could light up all of Seattle.",
  "You do thoughtful things for other people.",
  "You are close with your mom.",
  "You aren't dating Bing.",
  "You have the perfect sense of humor.",
  "You try to understand me.",
  "Your finger print unlocks my phone.",
  "You listen to me talk about programming.",
  "You are an excellent gift giver.",
  "You smell good.",
  "You want others around you to be happy.",
  "You are thoughtful of my mother.",
  "You have this funny evil laugh you do when you say something sarcastic.",
  "You want me to teach you to drive.",
  "You have a naturally creative mind.",
  "You enjoy what you do.",
  "You like Korean food!",
  "You don't care that I eat meat... Right?",
  "You are independent.",
  "You trust me.",
  "You can accomplish goals you set for yourself.",
  "You're easy to flirt with. ",
  "You have dreams, and want to achieve them.",
  "You motivate me.",
  "You have a good heart.",
  "My family loves you.",
  "You had the patience to sit through hours of missionary lessons!",
  "You have a unique taste in music, as far as I know anyway!",
  "You were always punctual to your lessons!",
  "You let me talk about my mission.",
  "You believe in God. ",
  "You have freckles.",
  "Even 10 seconds of hearing your voice can turn my day around.",
  "You think about others even when you are stressed.",
  "I have never known you to be selfish.",
  "I believe you are very representative of your culture.",
  "You are valued by your employers.",
  "You're an excellent kisser.",
  "I enjoy video chat with you, even if none of us are talking.",
  "You are smart enough to know when I tell you a fake English idiom.",
  "You speak English very, very well.",
  "I can relate to you on many things.",
  "You don't waste things.",
  "You have good hygiene.",
  "You send those hilarious lines of emojis like 🤔😴☎️😌",
  "We talk so often even though you're far away.",
  "You are a fast learner.",
  "You're willing to meet my extended family.",
  "You like traditional Chinese dramas.",
  "You are in good physical condition.",
  "You are mentally strong.",
  "You are an organized person.",
  "You think a lot.",
  "You are honest.",
  "I think broken English is really attractive. 😊",
  "You are 80% willing to try a hamburger someday.",
  "You finish what you start.",
  "You joke about being an old Japanese man.",
  "You give good compliments.",
  "You value others' opinion of you.",
  "You say \"I mean...\" a lot, and it's cute",
  "You have beautiful, beautiful dark eyes.",
  "You encourage me to do what's right.",
  "You are willing to make sacrifices for us.",
  "When I called you and told you I might be coming to Seattle next semester, you were excited and that made me feel good.",
  "You introduce me to good music.",
  "You like and share animal videos.",
  "You have a very good memory.",
  "You are excited to share things about your life with me.",
  "You are my sunshine.",
  "You didn't seem to mind my terrible singing!",
  "Everyone I know that has met you really likes you.",
  "You are very clever.",
  "It cracks me up when you try to do a deep voice.",
  "You think about and plan for your future.",
  "Our first date was perfect.",
  "You want me to go to bed on time!",
  "You are considerate.",
  "You don't snore.",
  "Your laugh is contagious.",
  "You know your way around Seattle, which is nice.",
  "Our relationship is an open one.",
  "I can talk to you just like I can talk to an American.",
  "You are genuine.",
  "You have black hair.",
  "Laurel is a great name.",
  "You have never unironically called me \"babe\".",
  "You are comfortable to be around.",
  "You make others feel important.",
  "You don't like to be a burden.",
  "You have a very sober mind.",
  "You teach me Chinese.",
  "It's fun to try and teach my family to try and say your name.",
  "You like me.",
  "You didn't slap me the first time I kissed you.",
  "I like holding your hand.",
  "You like going to concerts.",
  "You want to be better with children.",
  "You have faith.",
  "Your left thumb. ;)",
  "You have a unique personality.",
  "You are simple.",
  "You don't play games with other people's feelings.",
  "You don't *totally* hate America.",
  "You have great skin!",
  "You don't seem to age.",
  "The cute \"dun dun!\" sound you make, instead of \"ta-da\".",
  "You like animals.",
  "You try to trick me all the time.",
  "You say \"get rekt\"!",
  "Your dumplings are ❤️❤️❤️",
  "You help my mom.",
  "I've started making the same facial expressions as you.",
  "I like it when you say \"En~\"",
  "You don't mind being alone.",
  "You don't mind my love handles.",
  "Watching you play with kids...",
  "You don't mind that I think too much.",
  "You're adventurous.",
  "You have clean language.",
  "You have a tiny tongue.",
  "You want me to spend time with my family.",
  "You care about my career.",
  "You liked Fantastic Mr. Fox.",
  "You are great at snow fights.",
  "You are sociable with my family.",
  "I love the way you play card games/board games.",
  "You act so cute when you use a VR headset.",
  "You put up with me talking about Church.",
  "You remember birthdays.",
  "You have a great singing voice!",
  "You at least pretended to like my Christmas present. ;)",
  "You share things with others.",
  "You have a good taste in art.",
  "You like Disney music.",
  "Spirited Away is your favorite movie.",
  "You like Japanese stuff.",
  "Your lantern idea!",
  "You drew a picture for my brother.",
  "You make my family laugh.",
  "You like swing sets.",
  "You have a long attention span.",
  "You told me my hair smells like cheese, and yet you still like me.",
  "You are open about your past.",
  "You aren't prejudiced.",
  "You encourage me to take big steps in my career.",
  "You try to make that popping sound with your lips.",
  "You have soft hands.",
  "You like books.",
  "You understand a lot about me.",
  "You give me courage.",
  "You are tolerant of others.",
  "You have love in your heart.",
  "You tell me you love me.",
  "You can swim!",
  "You are a beautiful piano player.",
  "I like the way you say \"pecan\".",
  "You like board games.",
  "That freckle on your lip.",
  "You have an excellent figure.",
  "You call me \"pang\".",
  "You are good at ice skating.",
  "You get excited about small things.",
  "You say good prayers.",
  "You tell me you miss me all the time.",
  "You work hard even when you feel sick.",
  "You ask about my family.",
  "You have never gotten actually angry at me!",
  "You talk in your sleep.",
  "You make those around  you feel comfortable.",
  "You started liking me a long time ago.",
  "You have never even gotten close to making me mad.",
  "You are fun to dance with.",
  "The way you tilt your head back and forth when you're happy.",
  "There's this funny face that you make that I absolutely love.",
  "You give me snacks!",
  "You aren't all about money!",
  "You look super cute when you draw.",
  "You close your eyes when we kiss.",
  "You conserve water!",
  "Your mom is way nice.",
  "You keep your fingernails short.",
  "You let me help you with things.",
  "You love books, and you're cute about it.",
  "You are good at guessing your gifts.",
  "You make other people smile.",
  "You have great hair!",
  "Your name feels very natural for me to say.",
  "That look you gave me when I held your hand for 2 seconds on the street corner across from the aquarium.",
  "The look on your face when you are drawing something.",
  "You look for ways to help others.",
  "The way you shake your head \"no\" is really cute.",
  "You don't mind crying in front of me.",
  "You don't seem to think I'm a baby when I cry.",
  "Learning matters a lot to you.",
  "I get excited to tell you small details about my day.",
  "You don't think I'm a loser when you watch me scroll through my Facebook.",
  "Your Instagram is cooler than mine.",
  "Pecans & Dates.",
  "You like chocolate!",
  "You care about treating animals right.",
  "The way you look at your phone is super cute.",
  "That thing you do where you look away from me, and then look at me and blush, and then look away, and then do it again, over and over. :)",
  "You have crazy dreams (like the ones at night).",
  "I like you because I love you!",
  "You give me cute little gifts, like that pin.",
  "You aren't shy around my family.",
  "You drink hot water.  (Yes, it's a good reason)",
  "The way you jump when you are startled.",
  "You constantly remind me to be my best.",
  "You are warm.",
  "The way you scream when you get scared.",
  "You like to race.",
  "You rolled down a hill with me.",
  "You don't get mad.",
  "You are kind of ticklish.",
  "You bring me good luck!",
  "You hum to yourself sometimes.",
  "You like healthy snacks.",
  "You are accustomed to flying.",
  "You are easy to travel with.",
  "You acknowledge your flaws.",
  "You like Disney!",
  "You like Harry Potter.",
  "*I'll tell you this one when we're married*",
  "The way you say your own English name is so cute.",
  "You bow your head slightly when you are trying to be polite.",
  "The way you hide your face, and then check to see if I'm looking.",
  "There was this time that you all of a sudden seemed super, super shy around me, and I still don't know why, but it was very cute. :)",
  "I like your apartment.",
  "You are quaint.",
  "You ask me questions about my work.",
  "You took a course on Java!",
  "AND you let me help you with it!",
  "You crack me up!",
  "The sound of you breathing makes me happy.",
  "You are honest about spiritual things.",
  "You are reserved.",
  "You have a deep desire to improve yourself, and you share that with me.",
  "You have multiple very strong talents.",
  "The way you say \"bummer\".",
  "The way you say \"Really?\" sometimes.",
  "You have cute underwear. (Don't let anyone else see this list).",
  "You warn me against wasting time.",
  "You like to stand up after eating.",
  "The way you sleep.",
  "You show your gratitude.",
  "You don't smoke!",
  "You like walking to work.",
  "You just get up and go to parks sometimes!",
  "You have really cute pajamas.",
  "You have really soft skin.",
  "You like (or at least pretend to like) the things I make for you.",
  "You have a comic book collection!",
  "You are REALLY good at Dungeons and Dragons.",
  "You never, ever, ever have bad breath.",
  "You kiss me, even when I have bad breath.",
  "You make me feel safe.",
  "You don't trade what you want now for what you want most.",
  "You are patient with my mistakes.",
  "You have a genuine smile.",
  "You aren't actually upset that I'm getting fat.",
  "Your taste in baby girl names is hilarious.",
  "No matter what you say, I know you will be a very, very good mother.",
  "We have a few mutual friends.",
  "You are mostly okay with being in a Facebook relationship!",
  "You encourage me to be respectful to my parents.",
  "You want me to be nice to others.",
  "You picked a really good baptism scripture, and I was really impressed by that.",
  "God put you in my life.",
  "You used to not swing your arms when you walk.",
  "You like most of the music I like.",
  "I like how you order food at restaurants.",
  "I fall asleep easier if I can smell you.",
  "You surprise me with your abilities all the time.",
  "You don't give up on your dreams.",
  "You realize setbacks are temporary.",
  "You are tenacious.",
  "You help me with my goals.",
  "You remind me how important education is.",
  "You believe in science.",
  "I love the way you explain your drawings and concepts to me.",
  "You hang Chinese decorations.",
  "You sit in the dark sometimes.",
  "The way you blink when you wake up is really cute.",
  "Also, the \"Huh?\" noise you make when I wake you up is cute too.",
  "Also also, the face you make when you say \"Huh?\" when I wake you up is even cuter.",
  "You are constantly in my dreams.",
  "I can't even imagine bad things happening to you.",
  "You understand that truth isn't relative.",
  "You smell my hair?",
  "The way you cuddle up to me when you're cold.",
  "For some reason, you like videos that I record of myself?",
  "You can't spit.",
  "You are one of the only people who doesn't remind me of some animal.",
  "You like sweet potatoes.",
  "Speaking of which, you think those purple taro jelly things are sweet, and they have literally 0 sweetness to them.  It's funny!",
  "You let me fight you!",
  "You are short.",
  "You look especially good in blue.",
  "You look good in lipstick.",
  "I like the way your hand looks with a diamond on it.",
  "I know I would be happy with you forever.",
  "That feeling I had at the Cheesecake Factory was unforgettable.",
  "When we laughed together at the Guardians of the Galaxy movie (you were adorable).",
  "Your silly Chinese nicknames for me.",
  "You have really white teeth.",
  "You understand things about me that no one else does.",
  "I was able to write the last 100 reasons why I love you in under 15 minutes. There are so many things to love about you.",
  "You tell me what I need to hear, not what I want to hear.",
  "You are changed by the conversations you have with others.",
  "You were surprised to find out that I knew who Pikachu was.",
  "When you are thinking of something to say, sometimes you look up and smile, and it's super cute.",
  "You enjoy eating out, but you don't do it often.",
  "You give things I like a try, even if you don't like them.",
  "Your face is symmetrical.",
  "You make me miss you so much that I often look at plane tickets, even if I'm not planning a trip.",
  "You are very different from me.",
  "You can handle my dad's jokes.",
  "I am grateful for your patience with my progress in life.",
  "You hope to have a good family life.",
  "You invited me to go to China with you.",
  "You want to travel.",
  "You don't like sitting around.",
  "Many people look bad when they cry, but you don't.",
  "You are nice to my grandma.",
  "You give to the homeless.",
  "You work late when you need to.",
  "You designed a theater.",
  "You trust me when I try to comfort you.",
  "You liked sweets when you were a kid.",
  "You are cute when you shop at the Korean store.",
  "Your designs are improving every time!",
  "The way you push your hair behind your ears when you sit down to do something.",
  "That \"Oh!\" look you get when you're looking at something that surprises you.",
  "You used to make that same face even when you weren't surprised when we were teaching you in LA. :)",
  "Your dimples.",
  "You got your ears pierced as an adult.",
  "You are quick to recognize mistakes.",
  "You think different accents are funny.",
  "The way you wave your hand when you are saying no to something in a hurry.",
  "You use good perfumes.",
  "You are learning Japanese.",
  "You play Mario Kart with me!",
  "You recently started exercising again.",
  "You say I have good taste in movies.",
  "YOU have good taste in movies!",
  "You like playing on teams with me when we play games.",
  "You think some of my family members are funny.",
  "You are kind to Jared.",
  "Your colleagues and classmates really like being with you, especially the girls!",
  "You are not afraid of taking risks.",
  "You are brave.",
  "Even when things are hard, you still do what needs to be done.",
  "You like nuts.",
  "You starting calling apples æblers.",
  "Your British accent is hilarious.",
  "You've done a little bit of coding before!",
  "You like the rodent family.",
  "Your WeChat picture is a pika!",
  "You brush your teeth all the time.",
  "You find beauty in unconventional places sometimes.",
  "You are loyal.",
  "You bite people on the head when they finish a math problem faster than you.",
  "You are very popular!",
  "You impress your coworkers.",
  "You know how to put on a brave face.",
  "You are good at traveling.",
  "You have nice scarves.",
  "You like fruit snacks!",
  "The way you use caps lock is hilarious.",
  "You frequently text funny messages.",
  "You build LEGOs with me.",
  "You have fun socks.",
  "I like exploring malls with you.",
  "You have a very mature vocabulary.",
  "You look cute in headphones.",
  "You are learning to drive, and doing well!",
  "We eat fruit together.",
  "You are committed to quality in your work.",
  "Your designs are meaningful and well thought-out.",
  "You are reliable.",
  "You like Gatorade.",
  "You have a lot of fun graphic t-shirts.",
  "People have good memories of you.",
  "You know a lot about flowers.",
  "You like to use saunas.",
  "You don't cheat in school.",
  "You take me to great restaurants.",
  "You watched the greatest thing in the world with me, several times (Attack on Titan).",
  "I enjoy watching anime with you.",
  "You have stuffed animals.",
  "We hate the same movies.",
  "You look good in photos.",
  "You walk at a good pace.",
  "You are thoughtful about the way you reply to texts and emails.",
  "You are careful with money.",
  "You like giving gifts to friends.",
  "You at least pretend to like my cooking.",
  "The way you bob your head when listening to music.",
  "The way you look when you watch fireworks.",
  "You have fun in the snow.",
  "You look great in a swimsuit.",
  "You were the tallest Asian woman in your office!",
  "I enjoyed doing your physics projects with you.",
  "You are resourceful.",
  "You were pretty good at golf for your first time!",
  "I like seeing the Japanese blossoms with you!",
  "You look cute when you're on a video call.",
  "You encourage me to exercise.",
  "You were so kind to me when I was sick in 2022.",
  "You share drama and gossip with me.",
  "You have excellent prediction skills.",
  "You tell me unique details about your experiences.",
  "You are good at math.",
  "You strive to get good grades in school.",
  "You have generally clean language!",
  "We share lots of inside jokes.",
  "You help me improve my style!",
  "If I'm showing you something over WeChat, sometimes you squint your brow when looking at the screen.",
  "The way you only eat half of something and save the rest, even if it's something really tiny.",
  "You like sushi!",
  "You hate cinnamon.",
  "You are undefeated at both Munchkin AND Swashbuckled (board games).",
  "You show an interest in your friends' lives.",
  "You laugh at my Uncle Chris.",
  "We saw an eclipse together!",
  "You have good mental clarity.",
  "You are a good swimmer!",
  "We agree about Korean men.",
  "You like hiking.",
  "We've gone kayaking together!",
  "The way you smile when you listen to tour guides.",
  "I like getting food truck food with you.",
  "You run fast!",
  "We eat onigiri together.",
  "You sleep quietly and soundly.",
  "You can keep secrets.",
  "You try to make friends, and you're good at it!",
  "You have perfect boobs.",
  "You have good taste in shoes, especially boots.",
  "You take pictures of me.",
  "You make sure I'm well fed.",
  "We celebrate Christmas together.",
  "You look good in the snow.",
  "You like seafood.",
  "You take great pictures with your polaroid camera.",
  "You always make sure the drinks you order are not too sweet.",
  "You find great places to eat and take me to them.",
  "You got into an ivy league school.",
  "You sometimes make hilarious typos on WeChat.",
  "The little dance you do when you're in a good mood.",
  "You sometimes put things on your head to be cute.",
  "You like to make wishes.",
  "You are a sweet bully.",
  "You like dice rolling games!",
  "You look good in sundresses.",
  "You like Studio Ghibli.",
  "I have fun watching TV with you.",
  "We built furniture together!",
  "You look good in berets.",
  "We \"gambled\" in Vegas together!",
  "You like going to Japanese Bookstores.",
  "You sing \"Everybody wants to be a cat!\"",
  "You like Snoopy.",
  "You like tomatoes a lot.",
  "You look nice in punk style.",
  "The way you peek around corners.",
  "You take great ID photos!",
  "You have a green card, and you're still with me!",
  "You like cake, but you hate buttercream cake.",
  "You make cute doodles.",
  "Sometimes you put fruit or tomatoes in your cheeks to look like a hamster.",
  "You are quick at deciding what you want to eat.",
  "You are analytical.",
  "You looked great when you dyed your hair!",
  "You got me to buy an iPhone.",
  "Your sarcasm is funny!",
  "For the most part, we like similar actors.",
  "You want me to come with you when you go to the dentist.",
  "You let me help you with your physical model projects."
]
