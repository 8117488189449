// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog-upper-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0 20px 0;
  padding: 0 40px;
}

.back-link {
  font-size: 18px;
  color: #0075ff;
  cursor: pointer;
}

.back-link:hover {
  color: #bf00b7;
}

.full-post-date {
  font-size: 18px;
}

.full-post-title {
  text-align: left;
  color: white;
  font-size: 30px;
  font-weight: 100;
  margin: 0 0 10px 40px;
}

.big-star-bullet {
  display: inline;
  width: 34px;
  object-fit: contain;
  align-self: flex-start;
  margin-top: 5px;
}

.full-post-content {
  text-align: left;
  font-size: 18px;
  font-weight: 100;
  margin: 40px 0 10px 40px;
  overflow-y: auto;
  max-height: 575px;
  padding-right: 25px;
}

.full-post-content h2,
h3 {
  color: white;
}

.full-post-content strong {
  color: #e0ca00;
}

.full-post-content blockquote {
  border-left: 4px solid #e0ca00;
  padding-left: 10px;
  margin-left: 0;
  font-style: italic;
}

.full-post-content code {
  background-color: #0c318f;
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 16px;
}

.full-post-content pre code {
  display: block;
  background-color: transparent;
  color: white;
  padding: 5px 10px;
  border-radius: 0;
  font-size: 16px;
  width: 90%;
  border: 1px solid #959da5;
}

.full-post-content pre code:hover {
  background-color: #0c318f;
}

.full-post-content img {
  max-width: 100%;
  height: auto;
  margin: 20px 0;
}`, "",{"version":3,"sources":["webpack://./src/components/blog/post/post.scss","webpack://./src/styles/_colors.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,qBAAA;EACA,eAAA;AADF;;AAIA;EACE,eAAA;EACA,cCXK;EDYL,eAAA;AADF;;AAIA;EACE,cCdK;ADaP;;AAIA;EACE,eAAA;AADF;;AAIA;EACE,gBAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,qBAAA;AADF;;AAIA;EACE,eAAA;EACA,WAAA;EACA,mBAAA;EACA,sBAAA;EACA,eAAA;AADF;;AAIA;EACE,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,wBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AADF;;AAIA;;EAEE,YAAA;AADF;;AAIA;EACE,cCxDO;ADuDT;;AAIA;EACE,8BAAA;EACA,kBAAA;EACA,cAAA;EACA,kBAAA;AADF;;AAIA;EACE,yBCjES;EDkET,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,eAAA;AADF;;AAIA;EACE,cAAA;EACA,6BAAA;EACA,YAAA;EACA,iBAAA;EACA,gBAAA;EACA,eAAA;EACA,UAAA;EACA,yBAAA;AADF;;AAIA;EACE,yBCpFS;ADmFX;;AAIA;EACE,eAAA;EACA,YAAA;EACA,cAAA;AADF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
