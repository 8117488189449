// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog-margin {
  margin-top: 30px;
}

.comments-coming-soon {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 90px;
  font-size: 22px;
}`, "",{"version":3,"sources":["webpack://./src/components/blog/blog.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,kBAAA;EACA,OAAA;EACA,QAAA;EACA,YAAA;EACA,eAAA;AACF","sourcesContent":[".blog-margin {\n  margin-top: 30px;\n}\n\n.comments-coming-soon {\n  position: absolute;\n  left: 0;\n  right: 0;\n  bottom: 90px;\n  font-size: 22px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
