import React, { useState } from 'react'
import FallingLeaf from './falling-leaf'
import './anniversary.scss'
import { list } from './anniversary-list'

const Anniversary = () => {
  const [passcode, setPasscode] = useState('')
  const [isApproved, setIsApproved] = useState(false)

  const handleApprove = () => {
    if (passcode === 'cashews') {
      setIsApproved(true)
    } else setPasscode('')
  }

  // const leaves = Array.from({ length: 6 }).map((_, i) => (
  //   <FallingLeaf
  //     key={i}
  //     start={Math.random() * 100}
  //     delay={Math.random() * 5}
  //   />
  // ))

  return (
    <div className="standalone-page">
      {!isApproved ? (
        <div className="anni-input-container">
          <h1 className="anni-input-title">Enter the passcode</h1>
          <input
            className="anni-input"
            type="password"
            value={passcode}
            onChange={e => setPasscode(e.target.value)}
          />
          <button className="anni-button" onClick={handleApprove}>
            Enter
          </button>
        </div>
      ) : (
        <div className="anni-list-container">
          {/*{leaves}*/}
          <h1 className="anni-list-title">Reasons I like 李家瀛:</h1>
          <ol className="anni-list">
            {list.map(item => (
              <li className="anni-list-item" key={item}>
                {item}
              </li>
            ))}
          </ol>
        </div>
      )}
    </div>
  )
}

export default Anniversary
