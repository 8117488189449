import React from 'react'
import { cursors } from './cursor-select.logic'
import { useCursor } from '../../../../context/cursor-context'
import './cursor-select.scss'

const CursorSelect = () => {
  const { cursor, setCursor } = useCursor()

  const renderCursors = () => {
    return cursors.map((cursor, i) => {
      return (
        <img
          onClick={() => handleCursorClick(cursor.name)}
          alt={cursor.name}
          key={i}
          className="cursor"
          src={cursor.src}
        />
      )
    })
  }

  const handleCursorClick = cursor => {
    setCursor(cursor)
    localStorage.setItem('cursor', cursor)
  }

  const handleCursorClear = () => {
    setCursor(null)
    localStorage.removeItem('cursor')
  }

  return (
    <table
      className="cursor-table-border"
      bgcolor="grey"
      cellPadding="3"
      border="2"
    >
      <tbody>
        <tr>
          <td bgcolor="black">
            <div className="cursor-container">
              {cursor ? (
                <span className="clear-cursor" onClick={handleCursorClear}>
                  reset cursor
                </span>
              ) : (
                <span className="cursor-header">choose a cursor</span>
              )}
              <div className="cursor-selection">{renderCursors()}</div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default CursorSelect
