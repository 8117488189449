import React from 'react'
import './guestbook-graphics.scss'

import guestbookDesk from '../../../media/guestbook/guestbook-desk.gif'
import guestbookBadge from '../../../media/guestbook/guestbook-badge.gif'

const GuestbookGraphics = () => {
  return (
    <div className="guestbook-graphics-container">
      <img className="guestbook-desk" src={guestbookDesk} />
      <img className="guestbook-badge" src={guestbookBadge} />
    </div>
  )
}

export default GuestbookGraphics
