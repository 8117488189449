import { useState, useEffect } from 'react'

const useNumberOfItemsToShow = (
  itemRefs,
  maxContainerHeight,
  itemSpacing = 0,
  maxItems = 10
) => {
  const [numberOfItemsToShow, setNumberOfItemsToShow] = useState(maxItems)

  useEffect(() => {
    let totalHeight = 0
    let itemsToShow = 0

    itemRefs.current.forEach(itemRef => {
      const itemHeight = itemRef.clientHeight
      totalHeight += itemHeight + itemSpacing

      if (totalHeight <= maxContainerHeight) {
        itemsToShow++
      }
    })

    setNumberOfItemsToShow(itemsToShow)
  }, [itemRefs, maxContainerHeight])

  return numberOfItemsToShow
}

export default useNumberOfItemsToShow
