// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.featured-list-container {
  height: 507px;
  width: 210px;
  background-color: black;
  border: 2px solid #bf00b7;
  position: absolute;
  top: -2px;
  right: -235px;
  box-sizing: border-box;
  padding-top: 11px;
}

.featured-list-title {
  font-weight: 100;
  font-size: 18px;
  color: white;
  margin: 5px auto;
}

.featured-list {
  width: 155px;
}

.featured-list-item {
  text-align: left;
  font-size: 16px;
}

.frog {
  margin: 25px auto;
}

.lists-submessage {
  display: block;
  width: 170px;
  margin: 0 auto;
  font-size: 18px;
}

.badges-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 10px;
  width: 100%;
}

.badge {
  display: block;
  margin: 1px 2px;
}`, "",{"version":3,"sources":["webpack://./src/components/about/featured-list/featured-list.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,YAAA;EACA,uBAAA;EACA,yBAAA;EACA,kBAAA;EACA,SAAA;EACA,aAAA;EACA,sBAAA;EACA,iBAAA;AADF;;AAIA;EACE,gBAAA;EACA,eAAA;EACA,YAAA;EACA,gBAAA;AADF;;AAIA;EACE,YAAA;AADF;;AAIA;EACE,gBAAA;EACA,eAAA;AADF;;AAIA;EACE,iBAAA;AADF;;AAIA;EACE,cAAA;EACA,YAAA;EACA,cAAA;EACA,eAAA;AADF;;AAIA;EACE,aAAA;EACA,6BAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;AADF;;AAIA;EACE,cAAA;EACA,eAAA;AADF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
