// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comments-container {
  width: 75%;
  height: 340px;
  margin: 25px auto 0 auto;
  overflow-y: auto;
}

.no-comments {
  text-align: center;
  margin-top: 150px;
  font-size: 32px;
}

.comment {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 95%;
  margin: 15px auto 0 auto;
  text-align: left;
}

.comment-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;
}

.comment-name {
  font-size: 18px;
  color: white;
}

.comment-date {
  font-size: 16px;
}

.comment-message {
  border: 2px solid #0075ff;
  padding: 10px;
  font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/components/guestbook/comments/comments.scss"],"names":[],"mappings":"AAEA;EACE,UAAA;EACA,aAAA;EACA,wBAAA;EACA,gBAAA;AADF;;AAIA;EACE,kBAAA;EACA,iBAAA;EACA,eAAA;AADF;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,UAAA;EACA,wBAAA;EACA,gBAAA;AADF;;AAIA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,kBAAA;AADF;;AAIA;EACE,eAAA;EACA,YAAA;AADF;;AAIA;EACE,eAAA;AADF;;AAIA;EACE,yBAAA;EACA,aAAA;EACA,eAAA;AADF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
